#cart-page {
  --padding-x: 12px;
  .cart {
    &-row {
      display: grid;
      align-items: center;
      grid-template-columns: 4fr 1fr 1fr 0.5fr;
      gap: clamp(1.25rem, -1.659rem + 4.545vw, 1.75rem);
    }
    &-header {
      position: sticky;
      top: 1rem;
      padding: 12px var(--padding-x);
      border-radius: 6px;
      z-index: 10;
      background-color: #f5f5fa;
      margin-bottom: 20px;
      label,
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #484848;
      }
      button i {
        color: $primary-text;
      }
    }
    &-item {
      padding-inline: var(--padding-x);
      &:not(:last-child) {
        border-bottom: 1px solid #dadada;
        margin-bottom: 25px;
      }
      &__vendor {
        margin-bottom: 8px;
        span {
          font-size: 15px;
          line-height: 20px;
          color: $primary-text;
          font-weight: 400;
        }
      }
      &__product {
        padding-block: 15px;
        & + .cart-item__product {
          border-top: 1px solid #dadada;
        }
        &-image {
          display: inline-block;
          border-radius: 8px;
          overflow: hidden;
          max-width: 56px;
          width: 100%;
          height: 56px;
          flex-shrink: 0;
          img {
            @include size(100%);
            object-fit: cover;
          }
        }
        &-content {
          a {
            color: $primary-text;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            transition: color 0.1s ease;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            &:hover {
              color: $primary-color;
            }
          }
          p {
            margin-top: 10px;
            color: #484848;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
        }
        &-price {
          line-height: 20px;
          font-size: 15px;
          font-weight: 500;
          color: $primary-text;
        }
      }
    }
    &-empty {
      display: flex;
      align-items: center;
      flex-direction: column;
      .content {
        margin-top: 60px;
        text-align: center;
        p {
          color: #484848;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 24px;
        }
      }
    }
  }
  .summary-order {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 34px;
    &__item {
      display: flex;
      gap: 24px;
      justify-content: space-between;
      align-items: center;
      .count {
        color: #484848;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        font-size: 12px;
        border-radius: 8px;
        max-width: 24px;
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #484848;
      }
      .image {
        max-width: 58px;
        height: 58px;
        width: 100%;
        border: 1px solid #dadada;
        border-radius: 8px;
        overflow: hidden;
        img {
          @include size(100%);
          object-fit: cover;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 7px;
        h4 {
          color: $primary-text;
          font-size: 15px;
          font-weight: 500;
          line-height: 16px;
        }
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: $primary-text;
          transition: color 0.1s ease;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          &:hover {
            color: $primary-color;
          }
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #484848;
        }
      }
    }
  }
  .summary-info {
    h5 {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $primary-text;
    }
    ul {
      span,
      p {
        font-weight: 400;
        line-height: 20px;
        font-size: $fz-primary-responsive;
      }
      span {
        color: #484848;
      }
      p {
        color: $primary-text;
      }
    }
    &__total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      .total-price {
        color: $primary-text;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }
      .price {
        color: #ff6d3b;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  // Cart step 2 Page
  .payment-methods {
    &__select {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 24px;
      .payment-option {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 14px 30px;
        border-radius: 6px;
        color: #484848;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        position: relative;
        border: 2px solid #ebebeb;
        &.active {
          border-color: $primary-color;
          color: $primary-color;
          pointer-events: none;
          .icon-checkmark {
            display: flex;
          }
        }
        .icon-checkmark {
          position: absolute;
          top: -0.6rem;
          right: -0.5rem;
          @include size(17px);
          background-color: $primary-color;
          color: white;
          border-radius: 100rem;
          font-size: 10px;
          display: none;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &__content {
      display: none;
      &.active {
        display: block;
      }
      input[type="radio"]:checked + label span {
        font-weight: bold;
      }
      label {
        display: flex;
        align-items: center;
        gap: 3px;
        span,
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
        }
      }
    }
    #walletContent {
      p {
        color: $primary-text;
      }
      p,
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .balance {
        display: flex;
        flex-direction: column;
        gap: 8px;
        span {
          color: #aaaaaa;
        }
      }
    }
  }
  .deposit {
    &-caption {
      font-size: 24px;
      line-height: 32px;
      color: $primary-text;
      font-weight: 600;
      margin-bottom: 16px;
    }
    &-desc {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: $primary-text;
    }
    &-infomation {
      margin-top: 35px;
      max-width: 450px;
      width: 100%;
      margin-inline: auto;
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: left;
      span,
      b {
        font-size: 15px;
        line-height: 20px;
        color: $primary-text;
      }
      span {
        font-weight: 500;
      }
    }
    .text-note {
      margin-top: 8px;
      color: #ff6d3b;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
    }
  }
  // Cart step 3 Page
  .alert {
    border-radius: 6px;
    padding: 16px 24px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    max-width: 928px;
    margin: 11px auto 24px auto;
    position: relative;
    &--success {
      background-color: #e1f4eb;
      border: 1px solid #8fd8b5;
    }
    &--error {
      background-color: #fef1d7;
      border: 1px solid #ff6d3b;
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 6px;
      h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $primary-text;
      }
      p {
        color: $primary-text;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &-close {
      color: $primary-text;
      font-size: 22px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 10px;
    }
  }
  .order-info {
    &__header {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      margin-bottom: 40px;
      h2 {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: $primary-text;
      }
      .status {
        display: flex;
        align-items: center;
        gap: 20px;
        &-id {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
        }
        &-line {
          height: 20px;
          width: 1.5px;
          background-color: #aaaaaa;
        }
        &-state {
          color: #ff6d3b;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .stepper-wrapper {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      gap: 15px;
      --size-counter: 48px;
      .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        &::before {
          position: absolute;
          content: "";
          border-bottom: 2px solid #dadada;
          width: 100%;
          top: calc(var(--size-counter) / 2);
          right: 50%;
          z-index: 2;
        }
        .step-counter {
          position: relative;
          z-index: 5;
          display: flex;
          justify-content: center;
          align-items: center;
          width: var(--size-counter);
          height: var(--size-counter);
          border-radius: 50%;
          background: white;
          border: 2px solid #dadada;
          margin-bottom: 8px;
          i {
            font-size: 16px;
            color: #dadada;
          }
        }
        &.completed {
          .step-counter {
            border-color: #739ef1;
            i {
              color: #739ef1;
            }
          }
          &::before {
            border-color: #739ef1;
            z-index: 3;
          }
        }
        &:first-child::before {
          content: none;
        }
        .step-name {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: $primary-text;
        }
        .step-date {
          margin-top: 6px;
          color: #aaaaaa;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .delivery {
      display: grid;
      grid-template-columns: 1fr 90px;
      align-items: flex-end;
      gap: 80px;
      padding-block: 40px;
      margin-block: 40px;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
    }
    .delivery-info {
      border: 1px solid #fabf48;
      border-radius: 6px;
      background-color: #fef1d7;
      padding: 16px 24px;
      display: flex;
      align-items: baseline;
      gap: 10px;
      &__content {
        display: flex;
        flex-direction: column;
        gap: 6px;
        &-title {
          color: $primary-text;
          font-weight: 500;
          line-height: 20px;
          font-size: 15px;
        }
        &-desc {
          color: $primary-text;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          button {
            color: $primary-color;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            transition: opacity 0.1s ease;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .address {
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $primary-text;
        margin-bottom: 20px;
      }
      b,
      p {
        color: $primary-text;
        font-size: 15px;
        line-height: 24px;
      }
      b {
        font-weight: 500;
      }
      p {
        font-weight: 400;
      }
    }
    #timeline {
      --flex-flow: column;
      --dot-size: 0.625rem;
      display: flex;
      flex-flow: var(--flex-flow);
      font-size: 0.875rem;
      &[data-completed="true"] > .timeline-group:last-child {
        --clr: #739ef1;
        --icon-bg: var(--clr);
        --icon-border: none;
        --svg-clr: #fff;
        --svg-size: 2rem;
      }
      &[data-reversed="true"] {
        --flex-flow: column-reverse;
      }
      &[data-reversed="false"] {
        .timeline-group:not(:first-of-type) {
          padding-bottom: 1rem;
        }
        .timeline-group:last-of-type::before {
          top: -2.625rem;
        }
      }
      &[data-reversed="true"] {
        .timeline-group:not(:last-of-type) {
          padding-top: 1rem;
        }
        .timeline-group:first-of-type::before {
          top: -2.625rem;
        }
      }
      .timeline-group {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-flow: var(--flex-flow);
        gap: 0.5rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 1rem;
          width: 1px;
          height: 100%;
          background: #dadada;
          z-index: -1;
          transition: all 0.4s ease-in-out;
        }
      }
      .timeline-item {
        display: grid;
        grid-template-columns: auto 125px 1fr;
        gap: 20px;
      }
      .timeline-icon {
        --s: 34px;
        display: grid;
        place-items: center;
        border-radius: 100rem;
        border: var(--icon-border, 1.5px solid #dadada);
        background: var(--icon-bg, #fff);
        svg {
          height: var(--svg-size, 1rem);
          color: var(--svg-clr, #dadada);
        }
      }
      .timeline-dot {
        --s: 2rem;
        &::after {
          content: "";
          margin: 0 auto;
          transform: translateY(0.375rem);
          display: block;
          width: var(--dot-size);
          height: var(--dot-size);
          border-radius: inherit;
          background: #aaaaaa;
          border-radius: 100rem;
        }
      }
      .timeline-dot,
      .timeline-icon {
        width: var(--s);
        height: var(--s);
      }
      .timeline-text {
        display: flex;
        flex-flow: column;
      }
      .timeline-text,
      time {
        color: var(--clr);
      }
    }
  }
  #deliveryModal {
    .modal-body p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #484848;
    }
  }
  .order-summary {
    .shop-section {
      &__name {
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          font-size: 15px;
          line-height: 20px;
          color: $primary-text;
          font-weight: 400;
        }
      }
      & + .shop-section {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid #dadada;
      }
      &__product {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 20px;
        & + .shop-section__product {
          margin-top: 24px;
          padding-top: 24px;
          border-top: 1px solid #dadada;
        }
        .image {
          border: 1px solid #dadada;
          border-radius: 8px;
          overflow: hidden;
          max-width: 56px;
          height: 56px;
          width: 100%;
          flex-shrink: 0;
        }
        .details {
          display: flex;
          flex-direction: column;
          gap: 4px;
          &-name {
            font-weight: 400;
            font-size: 15px;
            color: $primary-text;
            line-height: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            transition: color 0.1s ease;
            &:hover {
              color: $primary-color;
            }
          }
          &-options,
          &-quantity {
            color: #484848;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
          }
        }
        .price {
          text-align: right;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: $primary-text;
        }
      }
    }
    .order-summary-total {
      margin-top: 26px;
      margin-left: auto;
      max-width: 600px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .summary-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        p {
          color: #484848;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
        }
        span {
          font-size: 15px;
          color: $primary-text;
          font-weight: 500;
          line-height: 20px;
          text-align: right;
        }
        .discount-amount {
          color: #739ef1;
        }
        .total-amount {
          color: #ff6d3b;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
        }
      }
      hr {
        margin-block: 6px;
      }
    }
  }
  @include maxWidth(767px) {
    .cart-header {
      top: 0;
      .cart-row {
        grid-template-columns: 1fr 0.3fr;
        gap: 15px;
        span {
          display: none;
        }
      }
    }
    .cart-item__vendor {
      .cart-row {
        grid-template-columns: 1fr;
      }
    }
    .cart-item__product {
      .cart-row {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 15px;
      }
    }
    .quantity-control {
      margin-block: unset;
    }
  }
  @include maxWidth(1199px) {
    .order-summary .shop-section__product {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
  @include maxWidth(1023px) {
    .order-info .delivery {
      gap: 20px;
    }
  }
  @include maxWidth(599px) {
    .alert {
      padding: 15px;
    }
    .payment-methods__select {
      gap: 15px;
      flex-direction: column;
      margin-bottom: 18px;
      .payment-option {
        padding: 15px;
        font-size: 15px;
        width: 100%;
      }
    }
    .order-info .delivery {
      display: flex;
      gap: 14px;
      margin-block: 20px;
      padding-block: 1.25rem;
      flex-direction: column;
      .delivery-info {
        padding: 15px;
      }
    }
    .order-info__header {
      align-items: center;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    }
    .order-info .stepper-wrapper .stepper-item {
      .step-counter {
        width: 42px;
        height: 42px;
        i {
          font-size: 15px;
        }
      }
      .step-name,
      .step-date {
        font-size: 14px;
      }
    }
    .order-info .address h3 {
      margin-bottom: 8px;
    }
    .order-info #timeline .timeline-item {
      grid-template-columns: auto 0.4fr 1fr;
      gap: 10px;
    }
  }
}
