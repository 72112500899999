.product {
  &-item {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: rgba(162, 173, 185, 0.3) 0px 7px 29px 0px;
    &__image {
      height: 0;
      position: relative;
      padding-bottom: 97.65%;
      overflow: hidden;
      img {
        @include size(100%);
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: all 0.2s ease;
      }
      @media (hover: hover) {
        &:hover img {
          transform: scale(1.1);
        }
      }
    }
    &__content {
      padding: 20px;
      h1 a {
        color: #292f36;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        @include lineClamp(2);
        transition: color 0.2s ease;
        img {
          @include size(24px !important);
          object-fit: cover;
          display: inline !important;
        }
        &:hover {
          color: #838af1;
        }
      }
      span {
        color: #ff6d3b;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      b {
        color: #606f80;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      p {
        color: #606f80;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  @include maxWidth(1199px) {
    &-item__image {
      padding-bottom: 80.25%;
    }
  }
  @include maxWidth(599px) {
    &-item__content {
      padding: 15px;
      h1 a {
        font-size: 14.5px;
      }
    }
  }
}
