#back-to-top {
  --size-button: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  z-index: 50;
  border: none;
  outline: none;
  cursor: pointer;
  width: var(--size-button);
  height: var(--size-button);
  font-size: 20px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(calc(100% + 40px));
  &.show {
    opacity: 1;
    transform: translateY(0);
  }
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 599px) {
    --size-button: 45px;
  }
}
