#profile-page {
  .account-info__form {
    .caption {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $primary-text;
      margin-bottom: 4px;
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      color: #484848;
      line-height: 20px;
      margin-bottom: 16px;
    }
    .form {
      label {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #484848;
      }
      .form-control {
        font-size: 15px;
        padding: 10px 15px;
      }
    }
  }
  .update-avatar {
    h2 {
      color: $primary-text;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .avatar {
      max-width: 120px;
      height: 120px;
      border-radius: 100rem;
      overflow: hidden;
      img {
        object-fit: cover;
        @include size(100%);
      }
    }
    p {
      font-weight: 400;
      font-size: 14px;
      color: #aaa;
      line-height: 20px;
    }
  }
  .my-order {
    .tab-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow-x: auto;
      white-space: nowrap;
      gap: 24px;
      padding-bottom: 12px;
      margin-bottom: 5px;
      &__item {
        padding: 10px 20px;
        border-bottom: 2px solid transparent;
        font-weight: 500;
        color: $primary-text;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        transition: color 0.1s ease;
        &:hover {
          color: $primary-color;
        }
        &.active {
          pointer-events: none;
          color: $primary-color;
          border-color: $primary-color;
        }
      }
    }
    .search-order {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      gap: 14px;
      border: 1px solid #dadada;
      border-radius: 8px;
      padding: 6px 6px 6px 14px;
      transition: all 0.25s ease;
      &:focus-within {
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
      }
      input {
        @include size(100%);
        border: none;
        outline: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $primary-text;
        &::placeholder {
          color: #aaaa;
        }
      }
      button {
        flex-shrink: 0;
        background-color: #ebebeb;
        padding: 10px 12px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #484848;
        transition: opacity 0.1s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .order-status {
    & + .order-status {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid #dadada;
    }
    &__title {
      display: flex;
      gap: 12px;
      align-items: center;
      margin-bottom: 24px;
    }
    .order-item {
      display: grid;
      grid-template-columns: 1fr 0.1fr;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      & + .order-item {
        margin-top: 18px;
        padding-top: 18px;
        border-top: 1px solid #dadada;
      }
      &__info {
        display: flex;
        gap: 24px;
      }
      &__image {
        max-width: 58px;
        height: 58px;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        img {
          object-fit: cover;
          @include size(100%);
        }
      }
      &__details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        a,
        p {
          color: $primary-text;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
        a {
          transition: color 0.1s ease;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          &:hover {
            color: $primary-color;
          }
        }
      }
      &__price {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: $primary-text;
        text-align: right;
      }
    }
    .order-total {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #484848;
    }
  }
  .address-management {
    .address-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 25px;
      & + .address-item {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #dadada;
      }
      &__info {
        .name {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $primary-text;
        }
        .phone {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
        }
        .separator {
          color: #aaaa;
        }
        .address {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: $primary-text;
        }
        .default {
          background-color: #fabf48;
          padding: 3px 8px;
          border-radius: 4px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #484848;
          text-align: center;
        }
      }
    }
  }
  .wallet-info {
    .wallet-tooltip {
      .tooltip-inner {
        background-color: $primary-color;
        color: #fff;
        max-width: 250px;
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        text-align: left;
      }
      .tooltip-arrow::before {
        border-top-color: $primary-color !important;
      }
      .tooltip-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          .tooltip-check {
            margin-right: 5px;
          }
        }
      }
    }
    .wallet-card {
      &__item {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        @include size(100%);
        min-height: 200px;
        padding: 24px;
        border-radius: 12px;
        border: 1px solid #ebebeb;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        &.primary {
          @include background-image(
            "../images/wallet-bg-primary.png",
            "../../../../assets/images/wallet-bg-primary.png"
          );
          color: white;
        }
        &.bgc-light {
          @include background-image(
            "../images/wallet-bg-light.png",
            "../../../../assets/images/wallet-bg-light.png"
          );
          color: $primary-text;
        }
        .card-header {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .amount {
          &-number {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            word-break: break-all;
          }
          &-rate {
            display: flex;
            align-items: center;
            gap: 10px;
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
            }
            i {
              font-size: 15px;
            }
          }
        }
      }
    }
    .history-trade {
      margin-block: 40px;
      .dropdownTrade {
        max-width: 180px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background-color: #ebebeb;
        border-radius: 6px;
        padding: 12px 16px;
        cursor: pointer;
        position: relative;
        &-main {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #484848;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
        &-list {
          position: absolute;
          top: calc(100% + 6px);
          left: 0;
          width: 100%;
          z-index: 20;
          border: 1px solid #dadada;
          background-color: white;
          border-radius: 8px;
          overflow: hidden;
          display: none;
          &__item {
            padding: 10px 12px;
            cursor: pointer;
            font-size: 14px;
            color: #484848;
            font-weight: 500;
            line-height: 20px;
            &.selected {
              background-color: $primary-color;
              color: white;
              cursor: default;
            }
            &:hover:not(.selected) {
              background-color: #eee;
            }
          }
        }
      }
      .divider {
        height: 30px;
        width: 1px;
        background-color: #aaaaaa;
      }
      .tab-list {
        display: flex;
        align-items: center;
        gap: 15px 24px;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        &__item {
          border-radius: 100rem;
          padding: 10px 12px;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          border: 1px solid #ebebeb;
          color: #484848;
          max-width: 140px;
          width: 100%;
          transition: all 0.1s ease;
          &:hover {
            color: $primary-color;
          }
          &.active {
            color: white;
            background-color: $primary-color;
            pointer-events: none;
          }
        }
      }
    }
    .table {
      .col {
        &-info {
          width: 55%;
        }
        &-time,
        &-status,
        &-amount {
          width: 15%;
        }
      }
      thead,
      tbody {
        vertical-align: middle;
      }
      thead th {
        font-weight: 400;
        font-size: 15px;
        color: $primary-text;
        line-height: 20px;
        padding-top: 15px;
        padding-bottom: 25px;
      }
      tbody td {
        padding-block: 18px;
      }
      .status {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        border-radius: 8px;
        padding: 4px 8px;
        text-align: center;
        display: inline-block;
        &--success {
          background-color: #e1f4eb;
          color: #8fd8b5;
        }
        &--fail {
          color: #ff6d3b;
          background-color: #fef1d7;
        }
        &--pending {
          color: #fabf48;
          background-color: #fef1d7;
        }
      }
      .amount {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        &--primary {
          color: $primary-color;
          font-weight: 500;
        }
        &--refund {
          color: #ff5a5a;
          font-weight: 500;
        }
        &--purchase {
          color: $primary-text;
          font-weight: 500;
        }
      }
      .transaction-icon {
        @include size(38px);
        background-color: #f1f3f5;
        border-radius: 50%;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        &.deposit {
          background-color: #e6f0ff;
          color: #3b82f6;
        }
        &.refund {
          background-color: #fff5e6;
          color: #ff9800;
        }
        &.purchase {
          background-color: #ffe6e6;
          color: #ff5a5a;
        }
      }
      .transaction-content {
        display: flex;
        flex-direction: column;
        gap: 4px;
        h4,
        p {
          font-size: 15px;
          line-height: 20px;
        }
        h4 {
          font-weight: 600;
          color: $primary-text;
        }
        p {
          font-weight: 400;
          color: #484848;
        }
      }
      .date {
        color: #484848;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  .change-password {
    max-width: 540px;
    form {
      label {
        color: #484848;
        font-size: 15px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .form-control {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        &::placeholder {
          color: #aaaaaa;
        }
      }
    }
  }
  #modalPayment,
  #modalFailure,
  #modalSuccess {
    .deposit {
      &-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: $primary-text;
      }
      &-info {
        background-color: #ebebeb;
        border: 1px solid #dadada;
        border-radius: 20px;
        padding: 24px;
        margin: 16px 0 20px 0;
        display: flex;
        justify-content: center;
        &__content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          max-width: 190px;
          width: 100%;
          .image {
            height: 190px;
            width: 100%;
            margin-bottom: 10px;
          }
          .amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
            span,
            strong {
              font-size: 14px;
              line-height: 20px;
              color: $primary-text;
            }
            span {
              font-weight: 400;
            }
            strong {
              font-weight: 600;
              word-break: break-all;
            }
          }
        }
      }
    }
    .timer-transaction {
      h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: $primary-text;
        margin-bottom: 8px;
      }
      &__content {
        background-color: #dae5fb;
        border-radius: 6px;
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        h4 {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
        }
        .countdown {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 8px;
          &__item {
            border-radius: 8px;
            @include size(36px);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: #739ef1;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: $light-color;
          }
          &__separator {
            color: #484848;
            font-size: 23px;
          }
        }
      }
    }
    .cancel-btn {
      margin-top: 20px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid #dadada;
      padding: 10px 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #484848;
      transition: all 0.1s ease;
      &:hover {
        background-color: $primary-color;
        color: $light-color;
      }
    }
    .wallet-header {
      background-color: #ebebeb;
      border-radius: 16px;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__image {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        span {
          color: $primary-text;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .status {
      margin-top: 40px;
      margin-bottom: 28px;
      &-caption {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: $primary-text;
        margin-bottom: 8px;
      }
      &-box {
        padding: 16px 24px;
        border-radius: 6px;
        background-color: transparent;
        border: 1px solid transparent;
        &--failed {
          background-color: #fef1d7;
          border-color: #fabf48;
          .status-box__title {
            color: #ff6d3b;
          }
        }
        &--success {
          border-color: #8fd8b5;
          background-color: #e1f4eb;
          .status-box__title {
            color: $primary-text;
          }
        }
        &__title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
        }
        &__content {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
        }
      }
    }
    .footer-controls {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      &__btn {
        flex: 1;
        border: 1px solid transparent;
        background-color: transparent;
        border-radius: 6px;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        transition: opacity 0.25s ease;
        &:hover {
          opacity: 0.8;
        }
        &--primary {
          border-color: #dadada;
          color: #484848;
        }
        &--secondary {
          background-color: $primary-color;
          color: white;
        }
      }
    }
  }
  .deposit {
    .balance {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 20px;
      border-radius: 12px;
      border: 1px solid #ebebeb;
      box-shadow: rgba(162, 173, 185, 0.2) 0px 7px 29px 0px;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #484848;
      }
      b {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: $primary-color;
      }
    }
    .promotion {
      background-color: #fef1d7;
      border-radius: 6px;
      padding: 16px 24px;
      margin-block: 40px;
      border: 1px solid #fabf48;
      &-caption {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;
        b {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $primary-text;
        }
      }
      &-list {
        list-style-type: disc;
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding-left: 50px;
        li {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
        }
      }
    }
    .payment-container {
      display: grid;
      gap: 45px;
      grid-template-columns: 58.8% 37.3%;
    }
    .amount-options {
      --gap-amount: 30px;
      &__normal .amount-option,
      &__most .amount-option {
        position: relative;
        border: 1px solid #ebebeb;
        border-radius: 12px;
        padding: 15px 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        transition: box-shadow 0.2s ease, border-color 0.2s ease;
        cursor: pointer;
        .icon-checkmark {
          position: absolute;
          top: -0.6rem;
          right: -0.5rem;
          @include size(17px);
          background-color: $primary-color;
          color: white;
          border-radius: 100rem;
          font-size: 10px;
          display: none;
          justify-content: center;
          align-items: center;
        }
        &.selected {
          box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
          border-color: $primary-color;
          pointer-events: none;
          .icon-checkmark {
            display: flex;
          }
        }
        .amount-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #484848;
        }
        .amount-price {
          font-weight: 500;
          font-size: 30px;
          line-height: 40px;
          color: #484848;
          word-break: break-all;
        }
      }
      &__normal {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--gap-amount);
        .amount-option {
          background-color: white;
        }
      }
      &__most {
        display: flex;
        flex-direction: column;
        gap: var(--gap-amount);
        margin-top: var(--gap-amount);
        .amount-option {
          background-color: #fef1d7;
          .most-selected {
            border-radius: 100rem;
            padding: 3px 8px;
            background-color: #fabf48;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #484848;
          }
          .bonus {
            background-color: #333333;
            padding: 4px 8px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #fffcfa;
          }
        }
      }
    }
    .summary {
      &-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        color: $primary-text;
      }
      &-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #484848;
          }
          strong {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $primary-text;
          }
          b {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #aaaaaa;
            &.active {
              color: $primary-color;
            }
          }
        }
      }
      &-total {
        padding-top: 12px;
        margin: 10px 0 20px 0;
        border-top: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
        }
        strong {
          color: #484848;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
  // Responsive all screen
  @include maxWidth(1399px) {
    .my-order .tab-list {
      gap: 15px;
    }
    .my-order .tab-list__item {
      padding-inline: 12px;
    }
    .wallet-info .history-trade {
      margin-block: 30px;
    }
    .wallet-info .wallet-card__item {
      min-height: 165px;
    }
  }
  @include maxWidth(991px) {
    .deposit {
      .payment-container {
        grid-template-columns: 1fr;
      }
    }
  }
  @include maxWidth(1023px) {
    .wallet-info .history-trade .tab-list {
      scrollbar-width: none;
    }
    .my-order .tab-list {
      scrollbar-width: none;
    }
  }
  @include maxWidth(767px) {
    .my-order .tab-list__item {
      padding: 10px;
    }
    .search-order button span {
      display: none;
    }
    .order-status .order-item,
    .order-status .order-item__info,
    .wallet-info .history-trade .tab-list {
      gap: 15px;
    }
  }
  @include maxWidth(575px) {
    .address-management .address-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
    .address-management .address-item__info .name {
      font-size: 15px;
    }
    .address-management .address-item__info .address {
      font-size: 14px;
    }
    .deposit {
      .promotion {
        margin-block: 25px;
      }
      .payment-container {
        gap: 30px;
      }
      .amount-options {
        --gap-amount: 15px;
        &__normal .amount-option,
        &__most .amount-option {
          .amount-price {
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
      .summary-title {
        margin-bottom: 15px;
      }
    }
  }
}
