.header {
  padding: 20px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid #dadada;
  z-index: 99;
  position: relative;
  background-color: white;
  &-bar {
    font-size: 24px;
    color: #505f77;
  }
  &-mobile__controls {
    display: flex;
    align-items: center;
    gap: 28px;
    .count {
      position: absolute;
      top: -14px;
      right: -14px;
      border-radius: 50%;
      font-size: 12px;
      background-color: #ff6d3b;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      height: 20px;
      padding-inline: 4px;
    }
    .cart {
      color: $primary-text;
    }
    .notification,
    .cart,
    .account {
      i {
        font-size: 20px;
        color: #505f77;
      }
    }
  }
  &-logo {
    overflow: hidden;
    position: relative;
    text-decoration: none;
    display: inline-block;
    max-width: clamp(9.625rem, 6.188rem + 14.667vw, 11.688rem);
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      @include size(100%);
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.7),
        transparent
      );
      transform: skewX(-25deg);
      animation: lightRay 5s infinite;
    }
    @keyframes lightRay {
      0% {
        left: -100%;
      }
      100% {
        left: 100%;
      }
    }
  }
  &-button {
    transition: color 0.2s ease;
    &:hover {
      color: $primary-color;
    }
  }
  &-search {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 620px;
    width: 100%;
    &__field {
      display: flex;
      align-items: center;
      gap: 12px;
      border: 1px solid #dadada;
      padding: 5.5px 5.5px 5.5px 8.4rem;
      border-radius: 8px;
      height: 50px;
      position: relative;
      transition: all 0.25s ease;
      &:focus-within {
        border-color: #86b7fe;
        box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
      }
      .dropdown {
        width: 120px;
        padding: 10px 15px;
        border-right: 1px solid #aaaaaa;
        left: 0;
        @include blockCenter("top");
        cursor: pointer;
        &-main {
          display: flex;
          align-items: center;
          gap: 15px;
          span {
            @include lineClamp(1);
          }
          i {
            font-size: 14px;
          }
        }
        &-main span,
        &-list li {
          color: $primary-text;
          font-weight: 500;
          font-size: clamp(0.875rem, 0.804rem + 0.357vw, 0.938rem);
          line-height: 20px;
        }
        &-list {
          display: none;
          width: 140px;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background-color: white;
          border: 1px solid #dadada;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          z-index: 50;
          li {
            padding: 10px 15px;
            transition: all 0.2s ease;
            &:hover {
              color: $primary-color;
            }
            &.active {
              color: $primary-color;
              background-color: #f5f5f5;
              cursor: auto;
            }
          }
        }
      }
      input {
        font-family: inherit;
        @include size(100%);
        border: none;
        color: $primary-text;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        outline: none;
        &::placeholder {
          color: #aaaaaa;
        }
      }
      button {
        background-color: #575fcc;
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 6px;
        color: #fffcfa;
        font-weight: 600;
        font-size: 14px;
        height: 100%;
        line-height: 20px;
        border-radius: 6px;
        padding: 12px;
        border: none;
        cursor: pointer;
        outline: none;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 15px;
        }
      }
    }
    &__listTag {
      display: flex;
      align-items: center;
      gap: 8px 24px;
      overflow-x: auto;
      white-space: nowrap;
      scrollbar-width: none;
      li {
        a {
          font-size: 14px;
          line-height: 16px;
          color: #484848;
          transition: color 0.2s ease;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
  &-controls {
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;
    max-width: 240px;
    height: 36px;
    .count {
      position: absolute;
      top: calc(-50% - -0.6rem);
      right: -1.6rem;
      min-width: 30px;
      height: 21px;
      border-radius: 100rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #ff6d3b;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      padding: 0 5px;
    }
    &__notification,
    &__cart {
      position: relative;
      i {
        font-size: 20px;
        color: $primary-color;
      }
    }
    &__notification {
      margin-right: 22px;
    }
    &__login {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 8px 12px;
      border-radius: 6px;
      background-color: #dae5fb;
      color: $primary-color;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      transition: opacity 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
    }
    &__avatar {
      border-radius: 100rem;
      border: 1.5px solid #dae5fb;
      overflow: hidden;
      img {
        @include size(100%);
        object-fit: cover;
      }
    }
    &__line {
      width: 1px;
      background-color: #aaaaaa;
    }
    &__cart {
      border: 1px solid #dae5fb;
      border-radius: 6px;
      padding: 7px 6px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-category {
    background-color: $light-color;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    z-index: 99;
    top: calc(100% + 30px);
    left: 0;
    width: 100%;
    border-radius: 10px;
    display: none;
    animation: fadeMenuIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
    @keyframes fadeMenuIn {
      from {
        opacity: 0;
        transform: translateY(-4rem);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &.active {
      display: block;
    }
    &__list {
      height: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-height: 500px;
      overflow-y: auto;
      scrollbar-width: thin;
      overscroll-behavior: none;
      li {
        border-radius: 6px;
        transition: background-color 0.1s ease;
        &:hover {
          background-color: #dae5fb;
        }
        &.active {
          background-color: #dae5fb;
        }
        a {
          color: #484848;
          width: 100%;
          font-size: $fz-primary-responsive;
          padding: 10px 12px;
          font-weight: 500;
          line-height: 20px;
          display: inline-block;
          i {
            color: #aaaaaa;
          }
        }
      }
    }
    &__submenu {
      display: none;
      .title-submenu a,
      .list-submenu li a {
        padding: 10px;
        color: #484848;
        border-radius: 6px;
        transition: all 0.1s ease;
        width: 100%;
        line-height: 20px;
        &:hover {
          color: $primary-color;
          background-color: #dae5fb;
        }
      }
      .title-submenu {
        a {
          font-weight: 600;
          font-size: 16px;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          i {
            color: #aaaaaa;
            font-size: 15px;
          }
        }
      }
      .list-submenu {
        li {
          a {
            display: inline-block;
            font-size: $fz-primary-responsive;
            font-weight: 400;
          }
        }
      }
    }
  }
  @include maxWidth(1500px) {
    &-search__field {
      height: 45px;
    }
    &-controls {
      height: 32px;
      .count {
        top: calc(-50% + 0.4rem);
      }
    }
  }
  @include maxWidth(1399px) {
    &-search__field input::placeholder {
      font-size: 12px;
    }
  }
  @include maxWidth(1199px) {
    &-search {
      max-width: 100%;
    }
  }
  @include maxWidth(599px) {
    &-search__field {
      padding-left: 7.8rem;
      .dropdown {
        width: 110px;
        .dropdown-main {
          gap: 10px;
        }
        i {
          font-size: 12px;
        }
      }
    }
    &-logo {
      width: 64%;
    }
  }
}

// Modal Notification
.modal-notification {
  position: fixed;
  top: 0;
  right: 0;
  width: clamp(18.75rem, 7.134rem + 43.732vw, 28.125rem);
  height: 100%;
  background-color: white;
  border-radius: 16px;
  z-index: 150;
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  &.show {
    display: block;
    transform: translateX(0);
  }
  .header-notification {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    .caption {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $primary-text;
    }
    img {
      width: 22px;
    }
  }
  .body-notification {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100% - 64px);
    overflow-y: auto;
    &__item {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      padding: 20px clamp(0.938rem, 0.241rem + 2.624vw, 1.5rem);
      transition: background-color 0.2s ease;
      &:hover {
        background-color: rgb(248, 174, 149, 0.4);
      }
      img {
        border-radius: 8px;
        object-fit: cover;
        @include size(48px);
      }
      &-content {
        .title {
          color: $primary-text;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
        .time {
          color: #484848;
          font-size: 12.5px;
          font-weight: 400;
          line-height: 16px;
        }
        .desc {
          font-weight: 400;
          font-size: 12.5px;
          line-height: 19px;
          color: #484848;
          b {
            font-weight: 700;
          }
        }
        .btn-main,
        .btn-secondary {
          padding: 8px 12px;
          border-radius: 6px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
        }
        .btn-main {
          background-color: #575fcc;
          color: $light-color;
        }
        .btn-secondary {
          color: $primary-color;
          background-color: #dae5fb;
        }
      }
    }
  }
}

// Modal Category Mobile
.category {
  &-mobile {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 20px;
    height: 100%;
  }
  &-tab {
    overflow-y: auto;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 3px;
    background-color: #f1f1f1;
    button {
      color: $primary-text;
      padding: 12px 16px;
      width: 100%;
      text-align: left;
      font-size: 15px;
      line-height: 21px;
      border: 2px solid transparent;
      &.active {
        border-right-color: #ff6d3b;
        background-color: #d6d6d6;
      }
    }
  }
  &-content {
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 20px;
    overflow-y: auto;
    &__submenu {
      h3 {
        line-height: 21px;
        a {
          font-weight: 600;
          font-size: 16px;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          color: $primary-text;
          width: 100%;
        }
      }
      ul {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        li a {
          color: #484848;
          border-radius: 6px;
          width: 100%;
          line-height: 21px;
          display: inline-block;
        }
      }
    }
  }
  @include maxWidth(767px) {
    &-tab button {
      text-align: center;
    }
    &-content__submenu {
      &:not([style*="display: none"]) + & {
        border-top: 1px solid #dadada;
        padding-top: 14px;
      }
    }
  }
}
