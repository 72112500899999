.breadcrumb {
  li {
    display: inline;
    font-size: 18px;
    &.active a {
      color: $primary-color;
      pointer-events: none;
    }
    & + li:before {
      padding: 11px;
      color: #aaaaaa;
      content: "/\00a0";
    }
    a {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #aaaaaa;
      text-decoration: none;
      transition: color 0.1s ease;
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
}
