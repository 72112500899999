.footer {
  padding: 100px 50px;
  .line {
    --gap-line: clamp(1.25rem, -1.786rem + 11.429vw, 2.5rem);
    padding-bottom: var(--gap-line);
    margin-bottom: var(--gap-line);
    border-bottom: 1px solid #dadada;
  }
  .title {
    margin-bottom: 11px;
    font-size: 16.5px;
    line-height: 20px;
    color: $primary-text;
    &.fw-500 {
      font-weight: 500;
    }
    &.fw-600 {
      font-weight: 600;
    }
  }
  &-certification {
    max-width: 150px;
    transition: opacity 0.2s ease;
    margin: 20px;
    &:hover {
      opacity: 0.8;
    }
    img {
      @include size(100%);
    }
  }
  &-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    &__address {
      max-width: 400px;
      width: 100%;
    }
  }
  &-list {
    --columns: 4;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(var(--columns), 1fr);
  }
  &-column {
    &__list,
    &__category {
      li a,
      li {
        color: $primary-text;
        font-weight: 400;
        font-size: $fz-primary-responsive;
        line-height: 20px;
      }
    }
    &__list,
    &__category {
      a {
        transition: all 0.1s ease;
        &:hover {
          color: $primary-color;
          text-decoration: underline;
        }
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &__category {
      h4 {
        margin-bottom: 6px;
        line-height: 20px;
        a {
          font-weight: 600;
          font-size: 15px;
          color: $primary-text;
        }
      }
      ul {
        li {
          display: inline;
          & + li::before {
            padding: 0 2px;
            content: "/\00a0";
            color: $primary-text;
          }
          a {
            word-break: break-word;
          }
        }
      }
    }
  }
  @include maxWidth(1199px) {
    padding-top: 60px;
    padding-inline: unset;
  }
  @include maxWidth(1023px) {
    padding-top: 40px;
    &-list {
      --columns: 2;
      gap: 30px;
    }
  }
  @include maxWidth(599px) {
    &-list {
      --columns: 1;
      gap: 1.2rem;
    }
    &-address {
      gap: 6px;
      flex-direction: column;
      align-items: flex-start;
    }
    &-certification {
      margin: unset;
    }
  }
}
