#faqs-page {
  .accordion-section {
    .accordion-item {
      &.active {
        .accordion-item__content {
          display: block;
        }
      }
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        padding: 10px 15px;
        background: #ebebeb;
        border-radius: 4px;
        cursor: pointer;
        i {
          transition: transform 0.3s ease;
          margin-left: auto;
          font-size: 14px;
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
      &__content {
        display: none;
        margin-block: 6px;
        a {
          padding: 8px 24px;
          color: #484848;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          display: inline-block;
          width: 100%;
          text-align: left;
          transition: color 0.1s ease;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
  .purchase {
    .caption {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      h1 {
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        color: $primary-text;
      }
      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: $primary-text;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #484848;
      }
    }
    .step {
      margin-bottom: 24px;
      &-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #484848;
        margin-bottom: 12px;
      }
      &-description {
        color: #484848;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      &-image {
        margin-top: 24px;
        width: 85%;
        height: 0;
        position: relative;
        padding-bottom: 37.95%;
        margin-inline: auto;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .note {
      border-top: 1px solid #dddde3;
      padding-top: 24px;
      margin-top: 24px;
      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #484848;
        margin-bottom: 12px;
      }
      p {
        color: #484848;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  @include maxWidth(767px) {
    .purchase .step-image {
      width: 100%;
      padding-bottom: 50%;
    }
    .purchase .caption {
      gap: 10px;
      h1 {
        font-size: 24px;
      }
    }
  }
}
