.btn-more {
  color: $primary-color;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s ease;
  &:hover {
    color: #838af1;
  }
  &.border-style {
    border: 1.5px solid $primary-color;
    border-radius: 6px;
    padding: 10px 16px;
    i {
      color: inherit;
      font-size: 15px;
    }
    @media (hover: hover) {
      &:hover {
        background-color: $primary-color;
        color: $light-color;
      }
    }
  }
}