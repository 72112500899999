#blog-page {
  .topbar {
    padding-block: 15px;
    background-color: $primary-color;
    color: white;
    &-divider {
      height: 20px;
      width: 1px;
      background-color: #aaaaaa;
    }
    &-login {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $light-color;
    }
  }
  .header-blog {
    text-align: center;
    &__caption {
      a {
        color: $primary-color;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        position: relative;
        padding-inline: 10px;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -100%;
          @include size(100%);
          background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.7),
            transparent
          );
          transform: skewX(-25deg);
          animation: lightRay 5s infinite;
        }
        @keyframes lightRay {
          0% {
            left: -100%;
          }
          100% {
            left: 100%;
          }
        }
      }
    }
    &__menu {
      padding-block: 26px;
      border-top: 1px solid #dadada;
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px 24px;
        li {
          a {
            display: inline-block;
            padding: 6px 12px;
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #484848;
            transition: color 0.1s ease;
            &:hover {
              color: $primary-color;
            }
            i {
              font-size: 12px;
            }
          }
        }
      }
      .button-search {
        @include size(35px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100rem;
        border: 1px solid #ebebeb;
        transition: all 0.1s ease;
        color: $primary-text;
        &:hover {
          color: white;
          background-color: $primary-color;
        }
      }
    }
  }
  .list-blog {
    &__item {
      border-radius: 12px;
      border: 1px solid #ebebeb;
      overflow: hidden;
      &-image {
        height: 0;
        position: relative;
        overflow: hidden;
        padding-bottom: 63.95%;
        @media (hover: hover) {
          &:hover img {
            transform: scale(1.2);
            opacity: 0.8;
          }
        }
        img {
          transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
          position: absolute;
          top: 0;
          left: 0;
          @include size(100%);
          object-fit: cover;
        }
        .label {
          position: absolute;
          top: 0;
          left: 0;
          background-color: $primary-color;
          color: white;
          padding: 8px;
          display: flex;
          flex-direction: column;
          gap: 2px;
          span {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: $light-color;
          }
          b {
            font-size: 10px;
            line-height: 12px;
            color: $light-color;
          }
        }
      }
      &-content {
        padding: 20px;
        .list-tag {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px 14px;
          a {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #484848;
            background-color: #ebebeb;
            padding: 5px 6px;
            border-radius: 4px;
            display: inline-block;
            text-transform: uppercase;
            transition: all 0.1s ease;
            &:hover {
              color: $primary-color;
              opacity: 0.8;
            }
          }
        }
        .title {
          color: $primary-text;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          transition: color 0.1s ease;
          margin-block: 15px;
          &:hover {
            color: $primary-color;
          }
        }
        .description {
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
    }
  }
  .search-field {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #dadada;
    border-radius: 100rem;
    padding: 6px 6px 6px 14px;
    input {
      font-family: inherit;
      @include size(100%);
      border: none;
      outline: none;
      color: $primary-text;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      &::placeholder {
        color: #aaaaaa;
      }
    }
    button {
      border-radius: 100rem;
      @include size(35px);
      background-color: #dadada;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      transition: opacity 0.1s ease;
      color: $primary-text;
      &:hover {
        opacity: 0.8;
      }
      i {
        font-size: 14px;
      }
    }
  }
  .post {
    &-caption {
      &__title {
        font-weight: 600;
        font-size: 48px;
        line-height: 60px;
        color: $primary-text;
        margin-bottom: 32px;
      }
      &__desc {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $primary-text;
        margin-bottom: 24px;
      }
      &__image {
        height: 0;
        position: relative;
        padding-bottom: 55.55%;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 24px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #484848;
      }
      .list-tag {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        &__item {
          background-color: #ebebeb;
          border-radius: 4px;
          display: inline-block;
          padding: 5px 6px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #484848;
          transition: color 0.1s ease;
          text-transform: uppercase;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    &-container {
      margin-bottom: 24px;
      max-width: 1060px;
      margin-inline: auto;
    }
    &-content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 500;
        font-size: clamp(1.125rem, 0.97rem + 0.583vw, 1.25rem);
        line-height: 30px;
        color: $primary-text;
        margin-bottom: 10px;
      }
      p {
        color: $primary-text;
        font-weight: 400;
        font-size: clamp(0.906rem, 0.79rem + 0.437vw, 1rem);
        line-height: 24px;
        margin-bottom: 10px;
      }
      img {
        max-width: 100%;
        width: 100%;
        border-radius: 12px;
        object-fit: cover;
        margin-bottom: 30px;
      }
    }
    &-summary {
      margin-top: 50px;
      &__contact {
        background-color: #ebebeb;
        border-radius: 12px;
        padding: 24px;
        display: flex;
        align-items: center;
        gap: 24px;
        .image {
          max-width: 152px;
          height: 152px;
          width: 100%;
          flex-shrink: 0;
          border-radius: 12px;
          overflow: hidden;
          img {
            object-fit: cover;
            @include size(100%);
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          span,
          a {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #484848;
          }
          b {
            color: #484848;
            font-size: 16px;
            line-height: 24px;
          }
          a {
            text-decoration: underline;
            transition: color 0.1s ease;
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  .no-result-message {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: $primary-text;
    span {
      color: $primary-color;
    }
  }
  @include maxWidth(1199px) {
    .post-caption__title {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px;
    }
  }
  @include maxWidth(767px) {
    .post-caption__title {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 12px;
    }
    .post-caption__desc {
      font-size: 15px;
      margin-bottom: 15px;
    }
    .post-related .row {
      display: grid;
      justify-content: flex-start;
      grid-auto-columns: 90%;
      grid-auto-flow: column;
      grid-gap: 1.2rem;
      overflow: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      scroll-snap-stop: always;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      -webkit-overflow-scrolling: touch;
      scroll-padding: 1rem;
      grid-template-columns: unset;
      &::-webkit-scrollbar {
        display: none;
        width: 0;
      }
      & > * {
        scroll-snap-align: start;
      }
    }
    .post-summary {
      margin-top: 25px;
      .post-summary__content {
        margin-block: 10px;
        font-size: 14px;
      }
    }
  }
  @include maxWidth(599px) {
    .no-result-message {
      font-size: 20px;
      line-height: 30px;
    }
    .post-summary__contact {
      flex-direction: column;
      .content {
        span,
        b {
          font-size: 15px;
        }
      }
    }
    .header-blog__caption a {
      font-size: 25px;
    }
    .header-blog__menu {
      padding-block: 17px;
      ul {
        gap: 10px;
      }
    }
    .list-blog__item-content {
      padding: 15px;
      .title {
        margin-block: 8px;
        font-size: 16px;
      }
    }
  }
}
