@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  color: #333333;
}

html {
  -webkit-text-size-adjust: 100%;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
button,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  line-height: 0;
  touch-action: manipulation;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-check-input:disabled {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  opacity: 1;
}

::selection {
  background-color: #575fcc;
  color: #fffcfa;
}

.no-scroll {
  overflow-y: hidden;
}

.color-primary {
  color: #575fcc;
}

.bgc-primary {
  background-color: #575fcc;
}

.btn-primary {
  padding: 10px;
  text-align: center;
  width: fit-content;
  border-radius: 6px;
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  transition: opacity 0.1s ease;
  font-weight: 600;
  font-size: clamp(0.875rem, 0.667rem + 0.889vw, 1rem);
  line-height: 24px;
}
.btn-primary:hover {
  opacity: 0.8;
}
.btn-primary-outline {
  border-color: #575fcc;
}

.warning-icon {
  width: clamp(1.25rem, 0.577rem + 0.769vw, 1.5rem);
}

.btn-disabled {
  background-color: #aaaaaa;
  color: #fffcfa;
  pointer-events: none;
}

.fz-primary {
  font-size: clamp(0.875rem, 0.652rem + 0.238vw, 0.938rem);
}

.input-group-text {
  padding: 10px 12px;
}
.input-group-text img {
  width: 25px;
}
@media only screen and (max-width: 1500px) {
  .input-group-text {
    padding: 7px 10px;
  }
  .input-group-text img {
    width: 24px;
  }
}

.message-invalid {
  font-size: 14px;
  color: #ff6d3b;
  margin-top: 8px;
  display: none;
}

.heading {
  font-weight: 500;
  color: #333333;
  font-size: clamp(1.25rem, 0.907rem + 1.29vw, 1.875rem);
  line-height: 36px;
}

.title {
  color: #333333;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

.overlay-mega-menu,
.overlay-notification,
.overlay-category {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.overlay-mega-menu {
  z-index: 80;
}

.overlay-notification {
  z-index: 120;
}

.overlay-category {
  z-index: 120;
}

.owl-carousel .owl-dot {
  margin-top: 0.5rem !important;
}
.owl-carousel .owl-dot.active span {
  background-color: #575fcc !important;
}
.owl-carousel .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fffcfa !important;
  width: 40px;
  height: 40px;
  border-radius: 100rem !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
  outline: none;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.owl-carousel .owl-nav button:active {
  opacity: 0.6;
}
.owl-carousel .owl-nav button:hover, .owl-carousel .owl-nav button:hover i {
  transition: all 0.2s ease;
  background-color: #575fcc !important;
  color: #fffcfa !important;
}
.owl-carousel .owl-nav .owl-prev {
  left: -60px;
}
.owl-carousel .owl-nav .owl-next {
  right: -60px;
}
.owl-carousel .owl-nav i {
  color: #333;
  font-size: 18px;
}

#product-slider .product-item {
  box-shadow: unset;
  border: 1.5px solid #e5e5e5;
}

#myCarousel .f-carousel__slide,
#myNavigation .f-carousel__slide {
  border-radius: 12px;
  overflow: hidden;
}
#myCarousel .f-carousel__slide img,
#myNavigation .f-carousel__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#myCarousel {
  --f-carousel-slide-width: 100%;
  --f-carousel-spacing: 20px;
  margin-bottom: 20px;
}
#myCarousel .f-carousel__nav {
  display: none;
}
#myCarousel .f-carousel__slide {
  width: 100%;
  max-height: 400px;
}

#myNavigation {
  --f-carousel-slide-width: calc(25% - 10px);
  --f-carousel-spacing: 10px;
}
#myNavigation .f-carousel__slide {
  max-height: 85px;
  width: 85px;
  border: 2.5px solid transparent;
}
#myNavigation .f-carousel__slide.is-nav-selected {
  border-color: #575fcc;
}

.quantity-control {
  margin-block: 24px;
  display: flex;
  gap: 12px;
}
.quantity-control--medium .quantity-control__minus,
.quantity-control--medium .quantity-control__plus,
.quantity-control--medium .quantity-control__value {
  width: 28px;
  height: 28px;
}
.quantity-control--large .quantity-control__minus,
.quantity-control--large .quantity-control__plus,
.quantity-control--large .quantity-control__value {
  width: 36px;
  height: 36px;
}
.quantity-control__minus, .quantity-control__plus, .quantity-control__value {
  border: 1px solid #484848;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-inline: 4px;
  color: #484848;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.quantity-control-label {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #484848;
  margin-bottom: 8px;
}
.quantity-control__value {
  outline-color: #739ef1;
}
.quantity-control__minus i, .quantity-control__plus i {
  font-size: 14px;
}
.quantity-control__minus.disabled, .quantity-control__plus.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.quantity-control__minus:active, .quantity-control__plus:active {
  background-color: #739ef1;
  color: white;
  border-color: #739ef1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background-color: #dae5fb;
}
.switch input:checked + .slider:before {
  transform: translateX(20px);
  background-color: white;
}
.switch input:checked + .slider:after {
  background-color: #575fcc;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e4e4e4;
  border-radius: 20px;
}
.switch .slider:before, .switch .slider:after {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  bottom: 2px;
  border-radius: 50%;
}
.switch .slider:before {
  left: 2px;
  background-color: white;
}
.switch .slider:after {
  right: 2px;
  background-color: transparent;
}

.profile-header {
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.profile-header__avatar {
  border-radius: 100rem;
  width: 45px;
  height: 45px;
  border: 1px solid #dae5fb;
  object-fit: cover;
}
.profile-header__name {
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.profile-menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.profile-menu__item {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #484848;
  transition: color 0.1s ease;
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  padding: 8px;
}
.profile-menu__item:hover {
  color: #575fcc;
}
.profile-menu__item.active {
  pointer-events: none;
  background-color: #dae5fb;
  border-radius: 6px;
  color: #575fcc;
}

.form-address .text,
.form-address .form-check-label {
  color: #333333;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.form-address .form-label {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #484848;
}
.form-address .form-control,
.form-address .form-select {
  font-size: 15px;
  padding: 10px 15px;
}
.form-address .form-control::placeholder,
.form-address .form-select::placeholder {
  color: #aaaaaa;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.pagination a {
  padding-inline: 10px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  height: 38px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #484848;
  transition: background-color 0.2s ease, color 0.2s ease;
}
.pagination a:hover {
  background-color: #f0f0f0;
}
.pagination a.active {
  background-color: #739ef1;
  color: #fff;
  border-color: #739ef1;
  pointer-events: none;
}
.pagination a.prev, .pagination a.next {
  background-color: #fff;
  color: #333333;
  border-color: #ddd;
}
.pagination a.prev.disabled, .pagination a.next.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.pagination a.prev:hover, .pagination a.prev:hover, .pagination a.next:hover, .pagination a.next:hover {
  background-color: #f0f0f0;
}

.product-tooltip .tooltip-inner {
  background-color: #575fcc;
  color: #fff;
  max-width: none;
  width: 282px;
  padding: 12px;
  border-radius: 10px;
  font-size: 12px;
  text-align: left;
}
.product-tooltip .tooltip-arrow::before {
  border-top-color: #575fcc !important;
}
.product-tooltip .tooltip-content {
  width: 100%;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.product-tooltip .tooltip-content__header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.wallet-tooltip .tooltip-inner {
  background-color: #575fcc;
  color: #fff;
  max-width: none;
  width: 282px;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  text-align: left;
}
.wallet-tooltip .tooltip-arrow::before {
  border-top-color: #575fcc !important;
}
.wallet-tooltip .tooltip-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.wallet-tooltip .tooltip-content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.wallet-tooltip .tooltip-content__header .tooltip-check {
  margin-right: 5px;
}

#button-listPage {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  background-color: #ff6d3b;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 16px;
  height: 60px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.1s ease;
  cursor: pointer;
}
#button-listPage i {
  font-size: 18px;
}
#button-listPage:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 599px) {
  #button-listPage {
    font-size: 14px;
    height: 55px;
    width: 28px;
  }
}

.list-group span {
  width: 32px;
  word-break: break-all;
}
.list-group a {
  color: #000;
  text-decoration: none;
  display: block;
  padding: 15px 20px;
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
.list-group a:hover {
  color: #007bff;
}

.header {
  padding: 20px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid #dadada;
  z-index: 99;
  position: relative;
  background-color: white;
}
.header-bar {
  font-size: 24px;
  color: #505f77;
}
.header-mobile__controls {
  display: flex;
  align-items: center;
  gap: 28px;
}
.header-mobile__controls .count {
  position: absolute;
  top: -14px;
  right: -14px;
  border-radius: 50%;
  font-size: 12px;
  background-color: #ff6d3b;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding-inline: 4px;
}
.header-mobile__controls .cart {
  color: #333333;
}
.header-mobile__controls .notification i,
.header-mobile__controls .cart i,
.header-mobile__controls .account i {
  font-size: 20px;
  color: #505f77;
}
.header-logo {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  display: inline-block;
  max-width: clamp(9.625rem, 6.188rem + 14.667vw, 11.688rem);
  width: 100%;
}
.header-logo img {
  width: 100%;
  height: auto;
}
.header-logo::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.7), transparent);
  transform: skewX(-25deg);
  animation: lightRay 5s infinite;
}
@keyframes lightRay {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.header-button {
  transition: color 0.2s ease;
}
.header-button:hover {
  color: #575fcc;
}
.header-search {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 620px;
  width: 100%;
}
.header-search__field {
  display: flex;
  align-items: center;
  gap: 12px;
  border: 1px solid #dadada;
  padding: 5.5px 5.5px 5.5px 8.4rem;
  border-radius: 8px;
  height: 50px;
  position: relative;
  transition: all 0.25s ease;
}
.header-search__field:focus-within {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
}
.header-search__field .dropdown {
  width: 120px;
  padding: 10px 15px;
  border-right: 1px solid #aaaaaa;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.header-search__field .dropdown-main {
  display: flex;
  align-items: center;
  gap: 15px;
}
.header-search__field .dropdown-main span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.header-search__field .dropdown-main i {
  font-size: 14px;
}
.header-search__field .dropdown-main span, .header-search__field .dropdown-list li {
  color: #333333;
  font-weight: 500;
  font-size: clamp(0.875rem, 0.804rem + 0.357vw, 0.938rem);
  line-height: 20px;
}
.header-search__field .dropdown-list {
  display: none;
  width: 140px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 50;
}
.header-search__field .dropdown-list li {
  padding: 10px 15px;
  transition: all 0.2s ease;
}
.header-search__field .dropdown-list li:hover {
  color: #575fcc;
}
.header-search__field .dropdown-list li.active {
  color: #575fcc;
  background-color: #f5f5f5;
  cursor: auto;
}
.header-search__field input {
  font-family: inherit;
  width: 100%;
  height: 100%;
  border: none;
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: none;
}
.header-search__field input::placeholder {
  color: #aaaaaa;
}
.header-search__field button {
  background-color: #575fcc;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 6px;
  color: #fffcfa;
  font-weight: 600;
  font-size: 14px;
  height: 100%;
  line-height: 20px;
  border-radius: 6px;
  padding: 12px;
  border: none;
  cursor: pointer;
  outline: none;
  transition: opacity 0.2s ease;
}
.header-search__field button:hover {
  opacity: 0.8;
}
.header-search__field button img {
  width: 15px;
}
.header-search__listTag {
  display: flex;
  align-items: center;
  gap: 8px 24px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
}
.header-search__listTag li a {
  font-size: 14px;
  line-height: 16px;
  color: #484848;
  transition: color 0.2s ease;
}
.header-search__listTag li a:hover {
  color: #575fcc;
}
.header-controls {
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  max-width: 240px;
  height: 36px;
}
.header-controls .count {
  position: absolute;
  top: calc(-50% + 0.6rem);
  right: -1.6rem;
  min-width: 30px;
  height: 21px;
  border-radius: 100rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6d3b;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 0 5px;
}
.header-controls__notification, .header-controls__cart {
  position: relative;
}
.header-controls__notification i, .header-controls__cart i {
  font-size: 20px;
  color: #575fcc;
}
.header-controls__notification {
  margin-right: 22px;
}
.header-controls__login {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #dae5fb;
  color: #575fcc;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  transition: opacity 0.2s ease;
}
.header-controls__login:hover {
  opacity: 0.8;
}
.header-controls__avatar {
  border-radius: 100rem;
  border: 1.5px solid #dae5fb;
  overflow: hidden;
}
.header-controls__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header-controls__line {
  width: 1px;
  background-color: #aaaaaa;
}
.header-controls__cart {
  border: 1px solid #dae5fb;
  border-radius: 6px;
  padding: 7px 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.header-category {
  background-color: #fffcfa;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: absolute;
  z-index: 99;
  top: calc(100% + 30px);
  left: 0;
  width: 100%;
  border-radius: 10px;
  display: none;
  animation: fadeMenuIn 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@keyframes fadeMenuIn {
  from {
    opacity: 0;
    transform: translateY(-4rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.header-category.active {
  display: block;
}
.header-category__list {
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
  overscroll-behavior: none;
}
.header-category__list li {
  border-radius: 6px;
  transition: background-color 0.1s ease;
}
.header-category__list li:hover {
  background-color: #dae5fb;
}
.header-category__list li.active {
  background-color: #dae5fb;
}
.header-category__list li a {
  color: #484848;
  width: 100%;
  font-size: clamp(0.875rem, 0.652rem + 0.238vw, 0.938rem);
  padding: 10px 12px;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
}
.header-category__list li a i {
  color: #aaaaaa;
}
.header-category__submenu {
  display: none;
}
.header-category__submenu .title-submenu a,
.header-category__submenu .list-submenu li a {
  padding: 10px;
  color: #484848;
  border-radius: 6px;
  transition: all 0.1s ease;
  width: 100%;
  line-height: 20px;
}
.header-category__submenu .title-submenu a:hover,
.header-category__submenu .list-submenu li a:hover {
  color: #575fcc;
  background-color: #dae5fb;
}
.header-category__submenu .title-submenu a {
  font-weight: 600;
  font-size: 16px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.header-category__submenu .title-submenu a i {
  color: #aaaaaa;
  font-size: 15px;
}
.header-category__submenu .list-submenu li a {
  display: inline-block;
  font-size: clamp(0.875rem, 0.652rem + 0.238vw, 0.938rem);
  font-weight: 400;
}
@media only screen and (max-width: 1500px) {
  .header-search__field {
    height: 45px;
  }
  .header-controls {
    height: 32px;
  }
  .header-controls .count {
    top: calc(-50% + 0.4rem);
  }
}
@media only screen and (max-width: 1399px) {
  .header-search__field input::placeholder {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-search {
    max-width: 100%;
  }
}
@media only screen and (max-width: 599px) {
  .header-search__field {
    padding-left: 7.8rem;
  }
  .header-search__field .dropdown {
    width: 110px;
  }
  .header-search__field .dropdown .dropdown-main {
    gap: 10px;
  }
  .header-search__field .dropdown i {
    font-size: 12px;
  }
  .header-logo {
    width: 64%;
  }
}

.modal-notification {
  position: fixed;
  top: 0;
  right: 0;
  width: clamp(18.75rem, 7.134rem + 43.732vw, 28.125rem);
  height: 100%;
  background-color: white;
  border-radius: 16px;
  z-index: 150;
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.modal-notification.show {
  display: block;
  transform: translateX(0);
}
.modal-notification .header-notification {
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}
.modal-notification .header-notification .caption {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #333333;
}
.modal-notification .header-notification img {
  width: 22px;
}
.modal-notification .body-notification {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100% - 64px);
  overflow-y: auto;
}
.modal-notification .body-notification__item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 20px clamp(0.938rem, 0.241rem + 2.624vw, 1.5rem);
  transition: background-color 0.2s ease;
}
.modal-notification .body-notification__item:hover {
  background-color: rgba(248, 174, 149, 0.4);
}
.modal-notification .body-notification__item img {
  border-radius: 8px;
  object-fit: cover;
  width: 48px;
  height: 48px;
}
.modal-notification .body-notification__item-content .title {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.modal-notification .body-notification__item-content .time {
  color: #484848;
  font-size: 12.5px;
  font-weight: 400;
  line-height: 16px;
}
.modal-notification .body-notification__item-content .desc {
  font-weight: 400;
  font-size: 12.5px;
  line-height: 19px;
  color: #484848;
}
.modal-notification .body-notification__item-content .desc b {
  font-weight: 700;
}
.modal-notification .body-notification__item-content .btn-main,
.modal-notification .body-notification__item-content .btn-secondary {
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
}
.modal-notification .body-notification__item-content .btn-main {
  background-color: #575fcc;
  color: #fffcfa;
}
.modal-notification .body-notification__item-content .btn-secondary {
  color: #575fcc;
  background-color: #dae5fb;
}

.category-mobile {
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 20px;
  height: 100%;
}
.category-tab {
  overflow-y: auto;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 3px;
  background-color: #f1f1f1;
}
.category-tab button {
  color: #333333;
  padding: 12px 16px;
  width: 100%;
  text-align: left;
  font-size: 15px;
  line-height: 21px;
  border: 2px solid transparent;
}
.category-tab button.active {
  border-right-color: #ff6d3b;
  background-color: #d6d6d6;
}
.category-content {
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 20px;
  overflow-y: auto;
}
.category-content__submenu h3 {
  line-height: 21px;
}
.category-content__submenu h3 a {
  font-weight: 600;
  font-size: 16px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: #333333;
  width: 100%;
}
.category-content__submenu ul {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.category-content__submenu ul li a {
  color: #484848;
  border-radius: 6px;
  width: 100%;
  line-height: 21px;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .category-tab button {
    text-align: center;
  }
  .category-content__submenu:not([style*="display: none"]) + .category-content__submenu {
    border-top: 1px solid #dadada;
    padding-top: 14px;
  }
}

.footer {
  padding: 100px 50px;
}
.footer .line {
  --gap-line: clamp(1.25rem, -1.786rem + 11.429vw, 2.5rem);
  padding-bottom: var(--gap-line);
  margin-bottom: var(--gap-line);
  border-bottom: 1px solid #dadada;
}
.footer .title {
  margin-bottom: 11px;
  font-size: 16.5px;
  line-height: 20px;
  color: #333333;
}
.footer .title.fw-500 {
  font-weight: 500;
}
.footer .title.fw-600 {
  font-weight: 600;
}
.footer-certification {
  max-width: 150px;
  transition: opacity 0.2s ease;
  margin: 20px;
}
.footer-certification:hover {
  opacity: 0.8;
}
.footer-certification img {
  width: 100%;
  height: 100%;
}
.footer-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.footer-address__address {
  max-width: 400px;
  width: 100%;
}
.footer-list {
  --columns: 4;
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(var(--columns), 1fr);
}
.footer-column__list li a,
.footer-column__list li, .footer-column__category li a,
.footer-column__category li {
  color: #333333;
  font-weight: 400;
  font-size: clamp(0.875rem, 0.652rem + 0.238vw, 0.938rem);
  line-height: 20px;
}
.footer-column__list a, .footer-column__category a {
  transition: all 0.1s ease;
}
.footer-column__list a:hover, .footer-column__category a:hover {
  color: #575fcc;
  text-decoration: underline;
}
.footer-column__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-column__category h4 {
  margin-bottom: 6px;
  line-height: 20px;
}
.footer-column__category h4 a {
  font-weight: 600;
  font-size: 15px;
  color: #333333;
}
.footer-column__category ul li {
  display: inline;
}
.footer-column__category ul li + li::before {
  padding: 0 2px;
  content: "/ ";
  color: #333333;
}
.footer-column__category ul li a {
  word-break: break-word;
}
@media only screen and (max-width: 1199px) {
  .footer {
    padding-top: 60px;
    padding-inline: unset;
  }
}
@media only screen and (max-width: 1023px) {
  .footer {
    padding-top: 40px;
  }
  .footer-list {
    --columns: 2;
    gap: 30px;
  }
}
@media only screen and (max-width: 599px) {
  .footer-list {
    --columns: 1;
    gap: 1.2rem;
  }
  .footer-address {
    gap: 6px;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-certification {
    margin: unset;
  }
}

.product-item {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgba(162, 173, 185, 0.3) 0px 7px 29px 0px;
}
.product-item__image {
  height: 0;
  position: relative;
  padding-bottom: 97.65%;
  overflow: hidden;
}
.product-item__image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: all 0.2s ease;
}
@media (hover: hover) {
  .product-item__image:hover img {
    transform: scale(1.1);
  }
}
.product-item__content {
  padding: 20px;
}
.product-item__content h1 a {
  color: #292f36;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: color 0.2s ease;
}
.product-item__content h1 a img {
  width: 24px !important;
  height: 24px !important;
  object-fit: cover;
  display: inline !important;
}
.product-item__content h1 a:hover {
  color: #838af1;
}
.product-item__content span {
  color: #ff6d3b;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.product-item__content b {
  color: #606f80;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.product-item__content p {
  color: #606f80;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
@media only screen and (max-width: 1199px) {
  .product-item__image {
    padding-bottom: 80.25%;
  }
}
@media only screen and (max-width: 599px) {
  .product-item__content {
    padding: 15px;
  }
  .product-item__content h1 a {
    font-size: 14.5px;
  }
}

.btn-more {
  color: #575fcc;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s ease;
}
.btn-more:hover {
  color: #838af1;
}
.btn-more.border-style {
  border: 1.5px solid #575fcc;
  border-radius: 6px;
  padding: 10px 16px;
}
.btn-more.border-style i {
  color: inherit;
  font-size: 15px;
}
@media (hover: hover) {
  .btn-more.border-style:hover {
    background-color: #575fcc;
    color: #fffcfa;
  }
}

#back-to-top {
  --size-button: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  z-index: 50;
  border: none;
  outline: none;
  cursor: pointer;
  width: var(--size-button);
  height: var(--size-button);
  font-size: 20px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(calc(100% + 40px));
}
#back-to-top.show {
  opacity: 1;
  transform: translateY(0);
}
@media (hover: hover) {
  #back-to-top:hover {
    opacity: 0.8;
  }
}
@media (max-width: 599px) {
  #back-to-top {
    --size-button: 45px;
  }
}

.breadcrumb li {
  display: inline;
  font-size: 18px;
}
.breadcrumb li.active a {
  color: #575fcc;
  pointer-events: none;
}
.breadcrumb li + li:before {
  padding: 11px;
  color: #aaaaaa;
  content: "/ ";
}
.breadcrumb li a {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #aaaaaa;
  text-decoration: none;
  transition: color 0.1s ease;
}
.breadcrumb li a:hover {
  color: #575fcc;
  text-decoration: underline;
}

#home-page .banner-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  aspect-ratio: 7.3/2.3;
}
#home-page .banner-list__image {
  border-radius: 20px;
  overflow: hidden;
}
#home-page .banner-list__image:nth-child(1) {
  grid-column: 1/5;
  grid-row: 1/3;
}
#home-page .banner-list__image:nth-child(2) {
  grid-column: 5/7;
  grid-row: 1/2;
}
#home-page .banner-list__image:nth-child(3) {
  grid-column: 5/7;
  grid-row: 2/3;
}
#home-page .banner-list__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home-page .services {
  margin-top: 40px;
}
#home-page .services__item {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: clamp(1.563rem, -0.991rem + 3.989vw, 2.5rem);
  border: 1.5px solid #dae5fb;
  border-radius: 24px;
}
#home-page .services__item span {
  color: #ff6d3b;
}
#home-page .category {
  margin: 100px 0;
}
#home-page .category-item {
  display: inline-block;
  transition: filter 0.2s ease;
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
  height: 0;
  position: relative;
  padding-bottom: 91.85%;
}
#home-page .category-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
#home-page .category-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home-page .category-item:hover {
  filter: brightness(1.2);
}
#home-page .category-item__content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
}
#home-page .category-item__content h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fffcfa;
}
#home-page .product-list {
  --column: 5;
  display: grid;
  gap: 40px 16px;
  grid-template-columns: repeat(var(--column), minmax(0, 1fr));
}
@media only screen and (max-width: 1199px) {
  #home-page .category {
    margin: 60px 0;
  }
  #home-page .product-list {
    --column: 3;
  }
}
@media only screen and (max-width: 1023px) {
  #home-page .banner-list {
    display: grid;
    justify-content: flex-start;
    grid-auto-columns: 90%;
    grid-auto-flow: column;
    grid-gap: 1.2rem;
    overflow: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-overflow-scrolling: touch;
    scroll-padding: 1rem;
    grid-template-columns: unset;
    aspect-ratio: 6.8/3;
  }
  #home-page .banner-list::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  #home-page .banner-list > * {
    scroll-snap-align: start;
  }
  #home-page .banner-list__image:not(:nth-child(999)) {
    grid-column: unset;
    grid-row: unset;
  }
}
@media only screen and (max-width: 767px) {
  #home-page .product-list {
    --column: 2;
  }
}
@media only screen and (max-width: 374px) {
  #home-page .product-list {
    --column: 1;
  }
}

#product-listPage .category {
  border: 1px solid #dadada;
  border-radius: 8px;
}
#product-listPage .category-header {
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  padding: 12px 15px;
}
#product-listPage .category-header h3 {
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
#product-listPage .category-body {
  padding-block: 10px;
}
#product-listPage .category-section.show .category-section__list {
  display: block;
}
#product-listPage .category-section.show .category-section__header i {
  transform: rotate(180deg);
}
#product-listPage .category-section__header {
  cursor: pointer;
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
#product-listPage .category-section__header span {
  font-size: 15px;
  color: #484848;
  font-weight: 500;
  line-height: 18px;
}
#product-listPage .category-section__header i {
  font-size: 14px;
  color: #aaaaaa;
}
#product-listPage .category-section__list {
  display: none;
}
#product-listPage .category-section__list li a {
  display: inline-block;
  width: 100%;
  padding: 8px 28px;
  color: #484848;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transition: color 0.1s ease;
}
#product-listPage .category-section__list li a:hover {
  color: #575fcc;
}
#product-listPage .brand-caption {
  margin: 28px 0 20px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}
#product-listPage .brand-item {
  transition: all 0.1s ease;
}
#product-listPage .brand-item__image {
  border-radius: 14px;
  overflow: hidden;
  height: 0;
  position: relative;
  padding-bottom: 100.25%;
}
#product-listPage .brand-item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#product-listPage .brand-item__title {
  margin-top: 12px;
  text-align: center;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
#product-listPage .brand-item:hover {
  opacity: 0.8;
}
#product-listPage .brand-item:hover h3 {
  color: #575fcc;
}
#product-listPage .product-list {
  --column: 4;
  display: grid;
  gap: 24px 16px;
  grid-template-columns: repeat(var(--column), minmax(0, 1fr));
}
#product-listPage #filter-mobile-button {
  font-size: 16px;
  line-height: 1.5;
  color: #fffcfa;
  font-weight: 400;
  background-color: #575fcc;
  border-radius: 5px;
  padding: 8px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  display: none;
}
@media only screen and (max-width: 1199px) {
  #product-listPage .product-list {
    --column: 3;
  }
}
@media only screen and (max-width: 1199px) and (max-width: 991px) {
  #product-listPage #filter-mobile-button {
    display: inline-flex;
  }
  #product-listPage #category-column {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    background-color: #fffcfa;
    z-index: 120;
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }
  #product-listPage #category-column.show {
    transform: translateX(0);
  }
  #product-listPage #category-column .category {
    height: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 1199px) and (max-width: 767px) {
  #product-listPage .product-list {
    gap: 15px;
    --column: 2;
  }
}

#product-detailPage .product-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#product-detailPage .product-info__brand span,
#product-detailPage .product-info__brand a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
#product-detailPage .product-info__brand span {
  color: #484848;
}
#product-detailPage .product-info__brand a {
  color: #575fcc;
}
#product-detailPage .product-info__title {
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
#product-detailPage .product-info__sold {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}
#product-detailPage .price-section {
  display: flex;
  flex-direction: column;
  gap: clamp(0.375rem, 0.169rem + 0.775vw, 0.75rem);
  padding-block: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dadada;
}
#product-detailPage .price-section__item {
  display: flex;
  align-items: center;
  gap: 10px;
}
#product-detailPage .price-section__label {
  width: 80px;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}
#product-detailPage .price-section__original, #product-detailPage .price-section__vnd {
  font-weight: 500;
  line-height: 24px;
  color: #ff6d3b;
}
#product-detailPage .price-section__original {
  font-size: 16px;
}
#product-detailPage .price-section__vnd {
  font-size: clamp(1rem, 0.645rem + 1.336vw, 1.5rem);
}
#product-detailPage .product-options {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 18px;
}
#product-detailPage .product-options .option-group {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
#product-detailPage .product-options .option-group__title {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #333333;
}
#product-detailPage .product-options .option-group__options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 12px;
}
#product-detailPage .product-options .option-group__options button {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #484848;
  color: #484848;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  transition: all 0.1s ease;
}
#product-detailPage .product-options .option-group__options button:hover {
  border-color: #739ef1;
  color: #739ef1;
}
#product-detailPage .product-options .option-group__options button.active {
  background-color: #739ef1;
  border-color: #739ef1;
  color: #fff;
  pointer-events: none;
}
#product-detailPage .info-section,
#product-detailPage .shipping-fees,
#product-detailPage .additional-services {
  background-color: #ebebeb;
  padding: 20px;
  border-radius: 8px;
}
#product-detailPage .info-section:not(:last-child),
#product-detailPage .shipping-fees:not(:last-child),
#product-detailPage .additional-services:not(:last-child) {
  margin-bottom: 16px;
}
#product-detailPage .info-section__title,
#product-detailPage .shipping-fees__title,
#product-detailPage .additional-services__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 20px;
}
#product-detailPage .info-section__item span, #product-detailPage .info-section__content span,
#product-detailPage .shipping-fees__item span,
#product-detailPage .shipping-fees__content span,
#product-detailPage .additional-services__item span,
#product-detailPage .additional-services__content span {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
}
#product-detailPage .info-section__item a, #product-detailPage .info-section__content a,
#product-detailPage .shipping-fees__item a,
#product-detailPage .shipping-fees__content a,
#product-detailPage .additional-services__item a,
#product-detailPage .additional-services__content a {
  text-align: center;
  color: #575fcc;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: color 0.1s ease;
}
#product-detailPage .info-section__item a:hover, #product-detailPage .info-section__content a:hover,
#product-detailPage .shipping-fees__item a:hover,
#product-detailPage .shipping-fees__content a:hover,
#product-detailPage .additional-services__item a:hover,
#product-detailPage .additional-services__content a:hover {
  color: #739ef1;
}
#product-detailPage .info-section__item i, #product-detailPage .info-section__content i,
#product-detailPage .shipping-fees__item i,
#product-detailPage .shipping-fees__content i,
#product-detailPage .additional-services__item i,
#product-detailPage .additional-services__content i {
  font-size: 18px;
}
#product-detailPage .info-section__item,
#product-detailPage .shipping-fees__item,
#product-detailPage .additional-services__item {
  display: flex;
  align-items: center;
  gap: 16px;
}
#product-detailPage .info-section__item + .shipping-fees__item, #product-detailPage .info-section__item + .additional-services__item,
#product-detailPage .shipping-fees__item + .shipping-fees__item,
#product-detailPage .shipping-fees__item + .additional-services__item,
#product-detailPage .additional-services__item + .shipping-fees__item,
#product-detailPage .additional-services__item + .additional-services__item {
  margin-top: 20px;
}
#product-detailPage .additional-services__item .services-price {
  margin-left: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #575fcc;
}
#product-detailPage .info-section__content {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
#product-detailPage .order-card__seller, #product-detailPage .order-card__exchange-rate {
  border: 1px solid #dadada;
  padding: 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #484848;
}
#product-detailPage .order-card__exchange-rate {
  margin-top: 8px;
}
#product-detailPage .order-card__exchange-rate span {
  color: #ff6d3b;
  margin-left: 4px;
}
#product-detailPage .order-card__product {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-block: 24px 0;
}
#product-detailPage .order-card__product img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;
}
#product-detailPage .order-card__product-details {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
}
#product-detailPage .order-card__subtotal {
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#product-detailPage .order-card__subtotal p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
#product-detailPage .order-card__subtotal b {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}
#product-detailPage .order-card__subtotal i {
  color: #333333;
}
#product-detailPage .order-card__actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#product-detailPage .product-detail__info {
  margin-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#product-detailPage .product-detail__info-item {
  display: grid;
  gap: 40px;
  grid-template-columns: 172px 1fr;
}
#product-detailPage .product-detail__info-item span {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #606f80;
}
#product-detailPage .product-detail__info-item p {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
#product-detailPage .product-detail__description p {
  font-size: clamp(0.875rem, 0.786rem + 0.334vw, 1rem);
  color: #484848;
  font-weight: 500;
  line-height: 1.6;
}

#login-page,
#register-page {
  --width-columnImage: 30%;
  background: #fffcfa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#login-page .column-image,
#register-page .column-image {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--width-columnImage);
  height: 100%;
}
#login-page .column-image img,
#register-page .column-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#login-page .form-container,
#register-page .form-container {
  margin-left: calc(var(--width-columnImage) + clamp(9.375rem, -1.42rem + 16.867vw, 13.75rem));
  width: 100%;
  max-width: 480px;
  border-radius: 8px;
  padding-block: 20px;
}
#login-page .form-container a,
#register-page .form-container a {
  color: #575fcc;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: opacity 0.1s ease;
}
#login-page .form-container a:hover,
#register-page .form-container a:hover {
  opacity: 0.8;
}
#login-page .form-header__caption,
#register-page .form-header__caption {
  margin-bottom: clamp(1.25rem, 0.415rem + 3.562vw, 2.125rem);
  color: #333333;
  font-weight: 700;
  font-size: clamp(1.5rem, 1.142rem + 1.527vw, 1.875rem);
  line-height: 36px;
}
#login-page .form-header__button,
#register-page .form-header__button {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  border: 1px solid #dadada;
  border-radius: 8px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #484848;
}
#login-page .form-footer,
#register-page .form-footer {
  text-align: center;
  margin-top: 24px;
}
#login-page .form-footer span,
#login-page .form-footer a,
#register-page .form-footer span,
#register-page .form-footer a {
  font-weight: 500;
  font-size: clamp(0.875rem, 0.652rem + 0.238vw, 0.938rem);
  line-height: 20px;
}
#login-page .form-footer span,
#register-page .form-footer span {
  color: #484848;
}
#login-page .form-footer a,
#register-page .form-footer a {
  color: #575fcc;
}
#login-page .or-divider,
#register-page .or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-block: clamp(1.563rem, 1.145rem + 1.781vw, 2rem);
}
#login-page .or-divider span,
#register-page .or-divider span {
  color: rgba(170, 170, 170, 0.6666666667);
  font-size: 15px;
  font-weight: 400;
  padding-inline: 20px;
  line-height: 20px;
}
#login-page .or-divider::before, #login-page .or-divider::after,
#register-page .or-divider::before,
#register-page .or-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dadada;
}
#login-page .or-divider::before,
#register-page .or-divider::before {
  margin-right: 0.25em;
}
#login-page .or-divider::after,
#register-page .or-divider::after {
  margin-left: 0.25em;
}
#login-page form label,
#register-page form label {
  color: #484848;
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
#login-page form .form-control,
#register-page form .form-control {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
#login-page form .form-control::placeholder,
#register-page form .form-control::placeholder {
  color: #aaaaaa;
}
@media only screen and (max-width: 1023px) {
  #login-page .column-image,
  #register-page .column-image {
    display: none;
  }
  #login-page .form-container,
  #register-page .form-container {
    margin-left: unset;
    max-width: 100%;
    padding: 20px;
  }
}

#auth-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}
#auth-page .wrapper {
  max-width: 540px;
  width: 100%;
  padding: 0 20px 20px 20px;
  text-align: center;
}
#auth-page .wrapper .content {
  margin-top: clamp(1.25rem, 0.565rem + 2.581vw, 2.5rem);
}
#auth-page .wrapper .content-title {
  font-weight: 700;
  font-size: clamp(1.5rem, 0.875rem + 2.667vw, 1.875rem);
  line-height: 40px;
  color: #333333;
  margin-bottom: 16px;
}
#auth-page .wrapper .content-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
#auth-page .wrapper .content-desc b {
  font-weight: 500;
}
#auth-page .wrapper .content .send-back span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
#auth-page .wrapper .content .send-back a {
  font-weight: 500;
  font-size: 16px;
  color: #575fcc !important;
  line-height: 24px;
  transition: opacity 0.24s ease;
}
#auth-page .wrapper .content .send-back a:hover {
  opacity: 0.8;
}
#auth-page .wrapper .content .back-to-page span,
#auth-page .wrapper .content .back-to-page a {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
#auth-page .wrapper .content .back-to-page span {
  color: #484848;
}
#auth-page .wrapper .content .back-to-page a {
  color: #575fcc;
  transition: opacity 0.24s ease;
}
#auth-page .wrapper .content .back-to-page a:hover {
  opacity: 0.8;
}
#auth-page .wrapper .content label {
  color: #484848;
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
#auth-page .wrapper .content input {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

#cart-page {
  --padding-x: 12px;
}
#cart-page .cart-row {
  display: grid;
  align-items: center;
  grid-template-columns: 4fr 1fr 1fr 0.5fr;
  gap: clamp(1.25rem, -1.659rem + 4.545vw, 1.75rem);
}
#cart-page .cart-header {
  position: sticky;
  top: 1rem;
  padding: 12px var(--padding-x);
  border-radius: 6px;
  z-index: 10;
  background-color: #f5f5fa;
  margin-bottom: 20px;
}
#cart-page .cart-header label,
#cart-page .cart-header span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}
#cart-page .cart-header button i {
  color: #333333;
}
#cart-page .cart-item {
  padding-inline: var(--padding-x);
}
#cart-page .cart-item:not(:last-child) {
  border-bottom: 1px solid #dadada;
  margin-bottom: 25px;
}
#cart-page .cart-item__vendor {
  margin-bottom: 8px;
}
#cart-page .cart-item__vendor span {
  font-size: 15px;
  line-height: 20px;
  color: #333333;
  font-weight: 400;
}
#cart-page .cart-item__product {
  padding-block: 15px;
}
#cart-page .cart-item__product + .cart-item__product {
  border-top: 1px solid #dadada;
}
#cart-page .cart-item__product-image {
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  max-width: 56px;
  width: 100%;
  height: 56px;
  flex-shrink: 0;
}
#cart-page .cart-item__product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#cart-page .cart-item__product-content a {
  color: #333333;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  transition: color 0.1s ease;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
#cart-page .cart-item__product-content a:hover {
  color: #575fcc;
}
#cart-page .cart-item__product-content p {
  margin-top: 10px;
  color: #484848;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
#cart-page .cart-item__product-price {
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
}
#cart-page .cart-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#cart-page .cart-empty .content {
  margin-top: 60px;
  text-align: center;
}
#cart-page .cart-empty .content p {
  color: #484848;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 24px;
}
#cart-page .summary-order {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 34px;
}
#cart-page .summary-order__item {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
}
#cart-page .summary-order__item .count {
  color: #484848;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  border-radius: 8px;
  max-width: 24px;
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #484848;
}
#cart-page .summary-order__item .image {
  max-width: 58px;
  height: 58px;
  width: 100%;
  border: 1px solid #dadada;
  border-radius: 8px;
  overflow: hidden;
}
#cart-page .summary-order__item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#cart-page .summary-order__item .content {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
#cart-page .summary-order__item .content h4 {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
}
#cart-page .summary-order__item .content a {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  transition: color 0.1s ease;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
#cart-page .summary-order__item .content a:hover {
  color: #575fcc;
}
#cart-page .summary-order__item .content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #484848;
}
#cart-page .summary-info h5 {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
#cart-page .summary-info ul span,
#cart-page .summary-info ul p {
  font-weight: 400;
  line-height: 20px;
  font-size: clamp(0.875rem, 0.652rem + 0.238vw, 0.938rem);
}
#cart-page .summary-info ul span {
  color: #484848;
}
#cart-page .summary-info ul p {
  color: #333333;
}
#cart-page .summary-info__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
#cart-page .summary-info__total .total-price {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
#cart-page .summary-info__total .price {
  color: #ff6d3b;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
#cart-page .payment-methods__select {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}
#cart-page .payment-methods__select .payment-option {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 14px 30px;
  border-radius: 6px;
  color: #484848;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  position: relative;
  border: 2px solid #ebebeb;
}
#cart-page .payment-methods__select .payment-option.active {
  border-color: #575fcc;
  color: #575fcc;
  pointer-events: none;
}
#cart-page .payment-methods__select .payment-option.active .icon-checkmark {
  display: flex;
}
#cart-page .payment-methods__select .payment-option .icon-checkmark {
  position: absolute;
  top: -0.6rem;
  right: -0.5rem;
  width: 17px;
  height: 17px;
  background-color: #575fcc;
  color: white;
  border-radius: 100rem;
  font-size: 10px;
  display: none;
  justify-content: center;
  align-items: center;
}
#cart-page .payment-methods__content {
  display: none;
}
#cart-page .payment-methods__content.active {
  display: block;
}
#cart-page .payment-methods__content input[type=radio]:checked + label span {
  font-weight: bold;
}
#cart-page .payment-methods__content label {
  display: flex;
  align-items: center;
  gap: 3px;
}
#cart-page .payment-methods__content label span,
#cart-page .payment-methods__content label p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#cart-page .payment-methods #walletContent p {
  color: #333333;
}
#cart-page .payment-methods #walletContent p,
#cart-page .payment-methods #walletContent span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
#cart-page .payment-methods #walletContent .balance {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#cart-page .payment-methods #walletContent .balance span {
  color: #aaaaaa;
}
#cart-page .deposit-caption {
  font-size: 24px;
  line-height: 32px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 16px;
}
#cart-page .deposit-desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}
#cart-page .deposit-infomation {
  margin-top: 35px;
  max-width: 450px;
  width: 100%;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}
#cart-page .deposit-infomation span,
#cart-page .deposit-infomation b {
  font-size: 15px;
  line-height: 20px;
  color: #333333;
}
#cart-page .deposit-infomation span {
  font-weight: 500;
}
#cart-page .deposit .text-note {
  margin-top: 8px;
  color: #ff6d3b;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}
#cart-page .alert {
  border-radius: 6px;
  padding: 16px 24px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  max-width: 928px;
  margin: 11px auto 24px auto;
  position: relative;
}
#cart-page .alert--success {
  background-color: #e1f4eb;
  border: 1px solid #8fd8b5;
}
#cart-page .alert--error {
  background-color: #fef1d7;
  border: 1px solid #ff6d3b;
}
#cart-page .alert-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
#cart-page .alert-content h2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}
#cart-page .alert-content p {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
#cart-page .alert-close {
  color: #333333;
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
}
#cart-page .order-info__header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 40px;
}
#cart-page .order-info__header h2 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
}
#cart-page .order-info__header .status {
  display: flex;
  align-items: center;
  gap: 20px;
}
#cart-page .order-info__header .status-id {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#cart-page .order-info__header .status-line {
  height: 20px;
  width: 1.5px;
  background-color: #aaaaaa;
}
#cart-page .order-info__header .status-state {
  color: #ff6d3b;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
#cart-page .order-info .stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 15px;
  --size-counter: 48px;
}
#cart-page .order-info .stepper-wrapper .stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
}
#cart-page .order-info .stepper-wrapper .stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #dadada;
  width: 100%;
  top: calc(var(--size-counter) / 2);
  right: 50%;
  z-index: 2;
}
#cart-page .order-info .stepper-wrapper .stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-counter);
  height: var(--size-counter);
  border-radius: 50%;
  background: white;
  border: 2px solid #dadada;
  margin-bottom: 8px;
}
#cart-page .order-info .stepper-wrapper .stepper-item .step-counter i {
  font-size: 16px;
  color: #dadada;
}
#cart-page .order-info .stepper-wrapper .stepper-item.completed .step-counter {
  border-color: #739ef1;
}
#cart-page .order-info .stepper-wrapper .stepper-item.completed .step-counter i {
  color: #739ef1;
}
#cart-page .order-info .stepper-wrapper .stepper-item.completed::before {
  border-color: #739ef1;
  z-index: 3;
}
#cart-page .order-info .stepper-wrapper .stepper-item:first-child::before {
  content: none;
}
#cart-page .order-info .stepper-wrapper .stepper-item .step-name {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
}
#cart-page .order-info .stepper-wrapper .stepper-item .step-date {
  margin-top: 6px;
  color: #aaaaaa;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
#cart-page .order-info .delivery {
  display: grid;
  grid-template-columns: 1fr 90px;
  align-items: flex-end;
  gap: 80px;
  padding-block: 40px;
  margin-block: 40px;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
#cart-page .order-info .delivery-info {
  border: 1px solid #fabf48;
  border-radius: 6px;
  background-color: #fef1d7;
  padding: 16px 24px;
  display: flex;
  align-items: baseline;
  gap: 10px;
}
#cart-page .order-info .delivery-info__content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
#cart-page .order-info .delivery-info__content-title {
  color: #333333;
  font-weight: 500;
  line-height: 20px;
  font-size: 15px;
}
#cart-page .order-info .delivery-info__content-desc {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
#cart-page .order-info .delivery-info__content-desc button {
  color: #575fcc;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  transition: opacity 0.1s ease;
}
#cart-page .order-info .delivery-info__content-desc button:hover {
  opacity: 0.8;
}
#cart-page .order-info .address h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 20px;
}
#cart-page .order-info .address b,
#cart-page .order-info .address p {
  color: #333333;
  font-size: 15px;
  line-height: 24px;
}
#cart-page .order-info .address b {
  font-weight: 500;
}
#cart-page .order-info .address p {
  font-weight: 400;
}
#cart-page .order-info #timeline {
  --flex-flow: column;
  --dot-size: 0.625rem;
  display: flex;
  flex-flow: var(--flex-flow);
  font-size: 0.875rem;
}
#cart-page .order-info #timeline[data-completed=true] > .timeline-group:last-child {
  --clr: #739ef1;
  --icon-bg: var(--clr);
  --icon-border: none;
  --svg-clr: #fff;
  --svg-size: 2rem;
}
#cart-page .order-info #timeline[data-reversed=true] {
  --flex-flow: column-reverse;
}
#cart-page .order-info #timeline[data-reversed=false] .timeline-group:not(:first-of-type) {
  padding-bottom: 1rem;
}
#cart-page .order-info #timeline[data-reversed=false] .timeline-group:last-of-type::before {
  top: -2.625rem;
}
#cart-page .order-info #timeline[data-reversed=true] .timeline-group:not(:last-of-type) {
  padding-top: 1rem;
}
#cart-page .order-info #timeline[data-reversed=true] .timeline-group:first-of-type::before {
  top: -2.625rem;
}
#cart-page .order-info #timeline .timeline-group {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: var(--flex-flow);
  gap: 0.5rem;
}
#cart-page .order-info #timeline .timeline-group::before {
  content: "";
  position: absolute;
  top: 0;
  left: 1rem;
  width: 1px;
  height: 100%;
  background: #dadada;
  z-index: -1;
  transition: all 0.4s ease-in-out;
}
#cart-page .order-info #timeline .timeline-item {
  display: grid;
  grid-template-columns: auto 125px 1fr;
  gap: 20px;
}
#cart-page .order-info #timeline .timeline-icon {
  --s: 34px;
  display: grid;
  place-items: center;
  border-radius: 100rem;
  border: var(--icon-border, 1.5px solid #dadada);
  background: var(--icon-bg, #fff);
}
#cart-page .order-info #timeline .timeline-icon svg {
  height: var(--svg-size, 1rem);
  color: var(--svg-clr, #dadada);
}
#cart-page .order-info #timeline .timeline-dot {
  --s: 2rem;
}
#cart-page .order-info #timeline .timeline-dot::after {
  content: "";
  margin: 0 auto;
  transform: translateY(0.375rem);
  display: block;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: inherit;
  background: #aaaaaa;
  border-radius: 100rem;
}
#cart-page .order-info #timeline .timeline-dot,
#cart-page .order-info #timeline .timeline-icon {
  width: var(--s);
  height: var(--s);
}
#cart-page .order-info #timeline .timeline-text {
  display: flex;
  flex-flow: column;
}
#cart-page .order-info #timeline .timeline-text,
#cart-page .order-info #timeline time {
  color: var(--clr);
}
#cart-page #deliveryModal .modal-body p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #484848;
}
#cart-page .order-summary .shop-section__name {
  display: flex;
  align-items: center;
  gap: 16px;
}
#cart-page .order-summary .shop-section__name span {
  font-size: 15px;
  line-height: 20px;
  color: #333333;
  font-weight: 400;
}
#cart-page .order-summary .shop-section + .shop-section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #dadada;
}
#cart-page .order-summary .shop-section__product {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}
#cart-page .order-summary .shop-section__product + .shop-section__product {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #dadada;
}
#cart-page .order-summary .shop-section__product .image {
  border: 1px solid #dadada;
  border-radius: 8px;
  overflow: hidden;
  max-width: 56px;
  height: 56px;
  width: 100%;
  flex-shrink: 0;
}
#cart-page .order-summary .shop-section__product .details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#cart-page .order-summary .shop-section__product .details-name {
  font-weight: 400;
  font-size: 15px;
  color: #333333;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: color 0.1s ease;
}
#cart-page .order-summary .shop-section__product .details-name:hover {
  color: #575fcc;
}
#cart-page .order-summary .shop-section__product .details-options, #cart-page .order-summary .shop-section__product .details-quantity {
  color: #484848;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
#cart-page .order-summary .shop-section__product .price {
  text-align: right;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #333333;
}
#cart-page .order-summary .order-summary-total {
  margin-top: 26px;
  margin-left: auto;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#cart-page .order-summary .order-summary-total .summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
#cart-page .order-summary .order-summary-total .summary-item p {
  color: #484848;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}
#cart-page .order-summary .order-summary-total .summary-item span {
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
}
#cart-page .order-summary .order-summary-total .summary-item .discount-amount {
  color: #739ef1;
}
#cart-page .order-summary .order-summary-total .summary-item .total-amount {
  color: #ff6d3b;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
#cart-page .order-summary .order-summary-total hr {
  margin-block: 6px;
}
@media only screen and (max-width: 767px) {
  #cart-page .cart-header {
    top: 0;
  }
  #cart-page .cart-header .cart-row {
    grid-template-columns: 1fr 0.3fr;
    gap: 15px;
  }
  #cart-page .cart-header .cart-row span {
    display: none;
  }
  #cart-page .cart-item__vendor .cart-row {
    grid-template-columns: 1fr;
  }
  #cart-page .cart-item__product .cart-row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
  }
  #cart-page .quantity-control {
    margin-block: unset;
  }
}
@media only screen and (max-width: 1199px) {
  #cart-page .order-summary .shop-section__product {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
@media only screen and (max-width: 1023px) {
  #cart-page .order-info .delivery {
    gap: 20px;
  }
}
@media only screen and (max-width: 599px) {
  #cart-page .alert {
    padding: 15px;
  }
  #cart-page .payment-methods__select {
    gap: 15px;
    flex-direction: column;
    margin-bottom: 18px;
  }
  #cart-page .payment-methods__select .payment-option {
    padding: 15px;
    font-size: 15px;
    width: 100%;
  }
  #cart-page .order-info .delivery {
    display: flex;
    gap: 14px;
    margin-block: 20px;
    padding-block: 1.25rem;
    flex-direction: column;
  }
  #cart-page .order-info .delivery .delivery-info {
    padding: 15px;
  }
  #cart-page .order-info__header {
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  #cart-page .order-info .stepper-wrapper .stepper-item .step-counter {
    width: 42px;
    height: 42px;
  }
  #cart-page .order-info .stepper-wrapper .stepper-item .step-counter i {
    font-size: 15px;
  }
  #cart-page .order-info .stepper-wrapper .stepper-item .step-name,
  #cart-page .order-info .stepper-wrapper .stepper-item .step-date {
    font-size: 14px;
  }
  #cart-page .order-info .address h3 {
    margin-bottom: 8px;
  }
  #cart-page .order-info #timeline .timeline-item {
    grid-template-columns: auto 0.4fr 1fr;
    gap: 10px;
  }
}

#profile-page .account-info__form .caption {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 4px;
}
#profile-page .account-info__form .desc {
  font-weight: 400;
  font-size: 14px;
  color: #484848;
  line-height: 20px;
  margin-bottom: 16px;
}
#profile-page .account-info__form .form label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #484848;
}
#profile-page .account-info__form .form .form-control {
  font-size: 15px;
  padding: 10px 15px;
}
#profile-page .update-avatar h2 {
  color: #333333;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
#profile-page .update-avatar .avatar {
  max-width: 120px;
  height: 120px;
  border-radius: 100rem;
  overflow: hidden;
}
#profile-page .update-avatar .avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#profile-page .update-avatar p {
  font-weight: 400;
  font-size: 14px;
  color: #aaa;
  line-height: 20px;
}
#profile-page .my-order .tab-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  white-space: nowrap;
  gap: 24px;
  padding-bottom: 12px;
  margin-bottom: 5px;
}
#profile-page .my-order .tab-list__item {
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  color: #333333;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  transition: color 0.1s ease;
}
#profile-page .my-order .tab-list__item:hover {
  color: #575fcc;
}
#profile-page .my-order .tab-list__item.active {
  pointer-events: none;
  color: #575fcc;
  border-color: #575fcc;
}
#profile-page .my-order .search-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 14px;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 6px 6px 6px 14px;
  transition: all 0.25s ease;
}
#profile-page .my-order .search-order:focus-within {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
}
#profile-page .my-order .search-order input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#profile-page .my-order .search-order input::placeholder {
  color: rgba(170, 170, 170, 0.6666666667);
}
#profile-page .my-order .search-order button {
  flex-shrink: 0;
  background-color: #ebebeb;
  padding: 10px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
  transition: opacity 0.1s ease;
}
#profile-page .my-order .search-order button:hover {
  opacity: 0.8;
}
#profile-page .order-status + .order-status {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #dadada;
}
#profile-page .order-status__title {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 24px;
}
#profile-page .order-status .order-item {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
#profile-page .order-status .order-item + .order-item {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid #dadada;
}
#profile-page .order-status .order-item__info {
  display: flex;
  gap: 24px;
}
#profile-page .order-status .order-item__image {
  max-width: 58px;
  height: 58px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
#profile-page .order-status .order-item__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#profile-page .order-status .order-item__details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#profile-page .order-status .order-item__details a,
#profile-page .order-status .order-item__details p {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
#profile-page .order-status .order-item__details a {
  transition: color 0.1s ease;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
#profile-page .order-status .order-item__details a:hover {
  color: #575fcc;
}
#profile-page .order-status .order-item__price {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #333333;
  text-align: right;
}
#profile-page .order-status .order-total {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #484848;
}
#profile-page .address-management .address-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}
#profile-page .address-management .address-item + .address-item {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #dadada;
}
#profile-page .address-management .address-item__info .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
#profile-page .address-management .address-item__info .phone {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#profile-page .address-management .address-item__info .separator {
  color: rgba(170, 170, 170, 0.6666666667);
}
#profile-page .address-management .address-item__info .address {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
}
#profile-page .address-management .address-item__info .default {
  background-color: #fabf48;
  padding: 3px 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #484848;
  text-align: center;
}
#profile-page .wallet-info .wallet-tooltip .tooltip-inner {
  background-color: #575fcc;
  color: #fff;
  max-width: 250px;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  text-align: left;
}
#profile-page .wallet-info .wallet-tooltip .tooltip-arrow::before {
  border-top-color: #575fcc !important;
}
#profile-page .wallet-info .wallet-tooltip .tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#profile-page .wallet-info .wallet-tooltip .tooltip-content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
#profile-page .wallet-info .wallet-tooltip .tooltip-content__header .tooltip-check {
  margin-right: 5px;
}
#profile-page .wallet-info .wallet-card__item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}
#profile-page .wallet-info .wallet-card__item.primary {
  background-image: url("../images/wallet-bg-primary.png");
  color: white;
}
#profile-page .wallet-info .wallet-card__item.bgc-light {
  background-image: url("../images/wallet-bg-light.png");
  color: #333333;
}
#profile-page .wallet-info .wallet-card__item .card-header {
  display: flex;
  align-items: center;
  gap: 12px;
}
#profile-page .wallet-info .wallet-card__item .card-header span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
#profile-page .wallet-info .wallet-card__item .amount-number {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  word-break: break-all;
}
#profile-page .wallet-info .wallet-card__item .amount-rate {
  display: flex;
  align-items: center;
  gap: 10px;
}
#profile-page .wallet-info .wallet-card__item .amount-rate span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
#profile-page .wallet-info .wallet-card__item .amount-rate i {
  font-size: 15px;
}
#profile-page .wallet-info .history-trade {
  margin-block: 40px;
}
#profile-page .wallet-info .history-trade .dropdownTrade {
  max-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #ebebeb;
  border-radius: 6px;
  padding: 12px 16px;
  cursor: pointer;
  position: relative;
}
#profile-page .wallet-info .history-trade .dropdownTrade-main {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
#profile-page .wallet-info .history-trade .dropdownTrade-list {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  width: 100%;
  z-index: 20;
  border: 1px solid #dadada;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  display: none;
}
#profile-page .wallet-info .history-trade .dropdownTrade-list__item {
  padding: 10px 12px;
  cursor: pointer;
  font-size: 14px;
  color: #484848;
  font-weight: 500;
  line-height: 20px;
}
#profile-page .wallet-info .history-trade .dropdownTrade-list__item.selected {
  background-color: #575fcc;
  color: white;
  cursor: default;
}
#profile-page .wallet-info .history-trade .dropdownTrade-list__item:hover:not(.selected) {
  background-color: #eee;
}
#profile-page .wallet-info .history-trade .divider {
  height: 30px;
  width: 1px;
  background-color: #aaaaaa;
}
#profile-page .wallet-info .history-trade .tab-list {
  display: flex;
  align-items: center;
  gap: 15px 24px;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
#profile-page .wallet-info .history-trade .tab-list__item {
  border-radius: 100rem;
  padding: 10px 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid #ebebeb;
  color: #484848;
  max-width: 140px;
  width: 100%;
  transition: all 0.1s ease;
}
#profile-page .wallet-info .history-trade .tab-list__item:hover {
  color: #575fcc;
}
#profile-page .wallet-info .history-trade .tab-list__item.active {
  color: white;
  background-color: #575fcc;
  pointer-events: none;
}
#profile-page .wallet-info .table .col-info {
  width: 55%;
}
#profile-page .wallet-info .table .col-time, #profile-page .wallet-info .table .col-status, #profile-page .wallet-info .table .col-amount {
  width: 15%;
}
#profile-page .wallet-info .table thead,
#profile-page .wallet-info .table tbody {
  vertical-align: middle;
}
#profile-page .wallet-info .table thead th {
  font-weight: 400;
  font-size: 15px;
  color: #333333;
  line-height: 20px;
  padding-top: 15px;
  padding-bottom: 25px;
}
#profile-page .wallet-info .table tbody td {
  padding-block: 18px;
}
#profile-page .wallet-info .table .status {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
  display: inline-block;
}
#profile-page .wallet-info .table .status--success {
  background-color: #e1f4eb;
  color: #8fd8b5;
}
#profile-page .wallet-info .table .status--fail {
  color: #ff6d3b;
  background-color: #fef1d7;
}
#profile-page .wallet-info .table .status--pending {
  color: #fabf48;
  background-color: #fef1d7;
}
#profile-page .wallet-info .table .amount {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}
#profile-page .wallet-info .table .amount--primary {
  color: #575fcc;
  font-weight: 500;
}
#profile-page .wallet-info .table .amount--refund {
  color: #ff5a5a;
  font-weight: 500;
}
#profile-page .wallet-info .table .amount--purchase {
  color: #333333;
  font-weight: 500;
}
#profile-page .wallet-info .table .transaction-icon {
  width: 38px;
  height: 38px;
  background-color: #f1f3f5;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
#profile-page .wallet-info .table .transaction-icon.deposit {
  background-color: #e6f0ff;
  color: #3b82f6;
}
#profile-page .wallet-info .table .transaction-icon.refund {
  background-color: #fff5e6;
  color: #ff9800;
}
#profile-page .wallet-info .table .transaction-icon.purchase {
  background-color: #ffe6e6;
  color: #ff5a5a;
}
#profile-page .wallet-info .table .transaction-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#profile-page .wallet-info .table .transaction-content h4,
#profile-page .wallet-info .table .transaction-content p {
  font-size: 15px;
  line-height: 20px;
}
#profile-page .wallet-info .table .transaction-content h4 {
  font-weight: 600;
  color: #333333;
}
#profile-page .wallet-info .table .transaction-content p {
  font-weight: 400;
  color: #484848;
}
#profile-page .wallet-info .table .date {
  color: #484848;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
#profile-page .change-password {
  max-width: 540px;
}
#profile-page .change-password form label {
  color: #484848;
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
#profile-page .change-password form .form-control {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
#profile-page .change-password form .form-control::placeholder {
  color: #aaaaaa;
}
#profile-page #modalPayment .deposit-title,
#profile-page #modalFailure .deposit-title,
#profile-page #modalSuccess .deposit-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #333333;
}
#profile-page #modalPayment .deposit-info,
#profile-page #modalFailure .deposit-info,
#profile-page #modalSuccess .deposit-info {
  background-color: #ebebeb;
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 24px;
  margin: 16px 0 20px 0;
  display: flex;
  justify-content: center;
}
#profile-page #modalPayment .deposit-info__content,
#profile-page #modalFailure .deposit-info__content,
#profile-page #modalSuccess .deposit-info__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 190px;
  width: 100%;
}
#profile-page #modalPayment .deposit-info__content .image,
#profile-page #modalFailure .deposit-info__content .image,
#profile-page #modalSuccess .deposit-info__content .image {
  height: 190px;
  width: 100%;
  margin-bottom: 10px;
}
#profile-page #modalPayment .deposit-info__content .amount,
#profile-page #modalFailure .deposit-info__content .amount,
#profile-page #modalSuccess .deposit-info__content .amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
#profile-page #modalPayment .deposit-info__content .amount span,
#profile-page #modalPayment .deposit-info__content .amount strong,
#profile-page #modalFailure .deposit-info__content .amount span,
#profile-page #modalFailure .deposit-info__content .amount strong,
#profile-page #modalSuccess .deposit-info__content .amount span,
#profile-page #modalSuccess .deposit-info__content .amount strong {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#profile-page #modalPayment .deposit-info__content .amount span,
#profile-page #modalFailure .deposit-info__content .amount span,
#profile-page #modalSuccess .deposit-info__content .amount span {
  font-weight: 400;
}
#profile-page #modalPayment .deposit-info__content .amount strong,
#profile-page #modalFailure .deposit-info__content .amount strong,
#profile-page #modalSuccess .deposit-info__content .amount strong {
  font-weight: 600;
  word-break: break-all;
}
#profile-page #modalPayment .timer-transaction h2,
#profile-page #modalFailure .timer-transaction h2,
#profile-page #modalSuccess .timer-transaction h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 8px;
}
#profile-page #modalPayment .timer-transaction__content,
#profile-page #modalFailure .timer-transaction__content,
#profile-page #modalSuccess .timer-transaction__content {
  background-color: #dae5fb;
  border-radius: 6px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#profile-page #modalPayment .timer-transaction__content h4,
#profile-page #modalFailure .timer-transaction__content h4,
#profile-page #modalSuccess .timer-transaction__content h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#profile-page #modalPayment .timer-transaction__content .countdown,
#profile-page #modalFailure .timer-transaction__content .countdown,
#profile-page #modalSuccess .timer-transaction__content .countdown {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}
#profile-page #modalPayment .timer-transaction__content .countdown__item,
#profile-page #modalFailure .timer-transaction__content .countdown__item,
#profile-page #modalSuccess .timer-transaction__content .countdown__item {
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #739ef1;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #fffcfa;
}
#profile-page #modalPayment .timer-transaction__content .countdown__separator,
#profile-page #modalFailure .timer-transaction__content .countdown__separator,
#profile-page #modalSuccess .timer-transaction__content .countdown__separator {
  color: #484848;
  font-size: 23px;
}
#profile-page #modalPayment .cancel-btn,
#profile-page #modalFailure .cancel-btn,
#profile-page #modalSuccess .cancel-btn {
  margin-top: 20px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #dadada;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #484848;
  transition: all 0.1s ease;
}
#profile-page #modalPayment .cancel-btn:hover,
#profile-page #modalFailure .cancel-btn:hover,
#profile-page #modalSuccess .cancel-btn:hover {
  background-color: #575fcc;
  color: #fffcfa;
}
#profile-page #modalPayment .wallet-header,
#profile-page #modalFailure .wallet-header,
#profile-page #modalSuccess .wallet-header {
  background-color: #ebebeb;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#profile-page #modalPayment .wallet-header__image,
#profile-page #modalFailure .wallet-header__image,
#profile-page #modalSuccess .wallet-header__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
#profile-page #modalPayment .wallet-header__image span,
#profile-page #modalFailure .wallet-header__image span,
#profile-page #modalSuccess .wallet-header__image span {
  color: #333333;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
#profile-page #modalPayment .status,
#profile-page #modalFailure .status,
#profile-page #modalSuccess .status {
  margin-top: 40px;
  margin-bottom: 28px;
}
#profile-page #modalPayment .status-caption,
#profile-page #modalFailure .status-caption,
#profile-page #modalSuccess .status-caption {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #333333;
  margin-bottom: 8px;
}
#profile-page #modalPayment .status-box,
#profile-page #modalFailure .status-box,
#profile-page #modalSuccess .status-box {
  padding: 16px 24px;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid transparent;
}
#profile-page #modalPayment .status-box--failed,
#profile-page #modalFailure .status-box--failed,
#profile-page #modalSuccess .status-box--failed {
  background-color: #fef1d7;
  border-color: #fabf48;
}
#profile-page #modalPayment .status-box--failed .status-box__title,
#profile-page #modalFailure .status-box--failed .status-box__title,
#profile-page #modalSuccess .status-box--failed .status-box__title {
  color: #ff6d3b;
}
#profile-page #modalPayment .status-box--success,
#profile-page #modalFailure .status-box--success,
#profile-page #modalSuccess .status-box--success {
  border-color: #8fd8b5;
  background-color: #e1f4eb;
}
#profile-page #modalPayment .status-box--success .status-box__title,
#profile-page #modalFailure .status-box--success .status-box__title,
#profile-page #modalSuccess .status-box--success .status-box__title {
  color: #333333;
}
#profile-page #modalPayment .status-box__title,
#profile-page #modalFailure .status-box__title,
#profile-page #modalSuccess .status-box__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}
#profile-page #modalPayment .status-box__content,
#profile-page #modalFailure .status-box__content,
#profile-page #modalSuccess .status-box__content {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#profile-page #modalPayment .footer-controls,
#profile-page #modalFailure .footer-controls,
#profile-page #modalSuccess .footer-controls {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
#profile-page #modalPayment .footer-controls__btn,
#profile-page #modalFailure .footer-controls__btn,
#profile-page #modalSuccess .footer-controls__btn {
  flex: 1;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  transition: opacity 0.25s ease;
}
#profile-page #modalPayment .footer-controls__btn:hover,
#profile-page #modalFailure .footer-controls__btn:hover,
#profile-page #modalSuccess .footer-controls__btn:hover {
  opacity: 0.8;
}
#profile-page #modalPayment .footer-controls__btn--primary,
#profile-page #modalFailure .footer-controls__btn--primary,
#profile-page #modalSuccess .footer-controls__btn--primary {
  border-color: #dadada;
  color: #484848;
}
#profile-page #modalPayment .footer-controls__btn--secondary,
#profile-page #modalFailure .footer-controls__btn--secondary,
#profile-page #modalSuccess .footer-controls__btn--secondary {
  background-color: #575fcc;
  color: white;
}
#profile-page .deposit .balance {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #ebebeb;
  box-shadow: rgba(162, 173, 185, 0.2) 0px 7px 29px 0px;
}
#profile-page .deposit .balance span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}
#profile-page .deposit .balance b {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #575fcc;
}
#profile-page .deposit .promotion {
  background-color: #fef1d7;
  border-radius: 6px;
  padding: 16px 24px;
  margin-block: 40px;
  border: 1px solid #fabf48;
}
#profile-page .deposit .promotion-caption {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
#profile-page .deposit .promotion-caption b {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}
#profile-page .deposit .promotion-list {
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 50px;
}
#profile-page .deposit .promotion-list li {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#profile-page .deposit .payment-container {
  display: grid;
  gap: 45px;
  grid-template-columns: 58.8% 37.3%;
}
#profile-page .deposit .amount-options {
  --gap-amount: 30px;
}
#profile-page .deposit .amount-options__normal .amount-option, #profile-page .deposit .amount-options__most .amount-option {
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 12px;
  padding: 15px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: box-shadow 0.2s ease, border-color 0.2s ease;
  cursor: pointer;
}
#profile-page .deposit .amount-options__normal .amount-option .icon-checkmark, #profile-page .deposit .amount-options__most .amount-option .icon-checkmark {
  position: absolute;
  top: -0.6rem;
  right: -0.5rem;
  width: 17px;
  height: 17px;
  background-color: #575fcc;
  color: white;
  border-radius: 100rem;
  font-size: 10px;
  display: none;
  justify-content: center;
  align-items: center;
}
#profile-page .deposit .amount-options__normal .amount-option.selected, #profile-page .deposit .amount-options__most .amount-option.selected {
  box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
  border-color: #575fcc;
  pointer-events: none;
}
#profile-page .deposit .amount-options__normal .amount-option.selected .icon-checkmark, #profile-page .deposit .amount-options__most .amount-option.selected .icon-checkmark {
  display: flex;
}
#profile-page .deposit .amount-options__normal .amount-option .amount-title, #profile-page .deposit .amount-options__most .amount-option .amount-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}
#profile-page .deposit .amount-options__normal .amount-option .amount-price, #profile-page .deposit .amount-options__most .amount-option .amount-price {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #484848;
  word-break: break-all;
}
#profile-page .deposit .amount-options__normal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-amount);
}
#profile-page .deposit .amount-options__normal .amount-option {
  background-color: white;
}
#profile-page .deposit .amount-options__most {
  display: flex;
  flex-direction: column;
  gap: var(--gap-amount);
  margin-top: var(--gap-amount);
}
#profile-page .deposit .amount-options__most .amount-option {
  background-color: #fef1d7;
}
#profile-page .deposit .amount-options__most .amount-option .most-selected {
  border-radius: 100rem;
  padding: 3px 8px;
  background-color: #fabf48;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #484848;
}
#profile-page .deposit .amount-options__most .amount-option .bonus {
  background-color: #333333;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fffcfa;
}
#profile-page .deposit .summary-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: #333333;
}
#profile-page .deposit .summary-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
#profile-page .deposit .summary-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
#profile-page .deposit .summary-list li span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}
#profile-page .deposit .summary-list li strong {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
#profile-page .deposit .summary-list li b {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #aaaaaa;
}
#profile-page .deposit .summary-list li b.active {
  color: #575fcc;
}
#profile-page .deposit .summary-total {
  padding-top: 12px;
  margin: 10px 0 20px 0;
  border-top: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
#profile-page .deposit .summary-total span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#profile-page .deposit .summary-total strong {
  color: #484848;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}
@media only screen and (max-width: 1399px) {
  #profile-page .my-order .tab-list {
    gap: 15px;
  }
  #profile-page .my-order .tab-list__item {
    padding-inline: 12px;
  }
  #profile-page .wallet-info .history-trade {
    margin-block: 30px;
  }
  #profile-page .wallet-info .wallet-card__item {
    min-height: 165px;
  }
}
@media only screen and (max-width: 991px) {
  #profile-page .deposit .payment-container {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 1023px) {
  #profile-page .wallet-info .history-trade .tab-list {
    scrollbar-width: none;
  }
  #profile-page .my-order .tab-list {
    scrollbar-width: none;
  }
}
@media only screen and (max-width: 767px) {
  #profile-page .my-order .tab-list__item {
    padding: 10px;
  }
  #profile-page .search-order button span {
    display: none;
  }
  #profile-page .order-status .order-item,
  #profile-page .order-status .order-item__info,
  #profile-page .wallet-info .history-trade .tab-list {
    gap: 15px;
  }
}
@media only screen and (max-width: 575px) {
  #profile-page .address-management .address-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  #profile-page .address-management .address-item__info .name {
    font-size: 15px;
  }
  #profile-page .address-management .address-item__info .address {
    font-size: 14px;
  }
  #profile-page .deposit .promotion {
    margin-block: 25px;
  }
  #profile-page .deposit .payment-container {
    gap: 30px;
  }
  #profile-page .deposit .amount-options {
    --gap-amount: 15px;
  }
  #profile-page .deposit .amount-options__normal .amount-option .amount-price, #profile-page .deposit .amount-options__most .amount-option .amount-price {
    font-size: 20px;
    line-height: 25px;
  }
  #profile-page .deposit .summary-title {
    margin-bottom: 15px;
  }
}

#blog-page .topbar {
  padding-block: 15px;
  background-color: #575fcc;
  color: white;
}
#blog-page .topbar-divider {
  height: 20px;
  width: 1px;
  background-color: #aaaaaa;
}
#blog-page .topbar-login {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fffcfa;
}
#blog-page .header-blog {
  text-align: center;
}
#blog-page .header-blog__caption a {
  color: #575fcc;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  position: relative;
  padding-inline: 10px;
  overflow: hidden;
}
#blog-page .header-blog__caption a::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.7), transparent);
  transform: skewX(-25deg);
  animation: lightRay 5s infinite;
}
@keyframes lightRay {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
#blog-page .header-blog__menu {
  padding-block: 26px;
  border-top: 1px solid #dadada;
}
#blog-page .header-blog__menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 24px;
}
#blog-page .header-blog__menu ul li a {
  display: inline-block;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #484848;
  transition: color 0.1s ease;
}
#blog-page .header-blog__menu ul li a:hover {
  color: #575fcc;
}
#blog-page .header-blog__menu ul li a i {
  font-size: 12px;
}
#blog-page .header-blog__menu .button-search {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100rem;
  border: 1px solid #ebebeb;
  transition: all 0.1s ease;
  color: #333333;
}
#blog-page .header-blog__menu .button-search:hover {
  color: white;
  background-color: #575fcc;
}
#blog-page .list-blog__item {
  border-radius: 12px;
  border: 1px solid #ebebeb;
  overflow: hidden;
}
#blog-page .list-blog__item-image {
  height: 0;
  position: relative;
  overflow: hidden;
  padding-bottom: 63.95%;
}
@media (hover: hover) {
  #blog-page .list-blog__item-image:hover img {
    transform: scale(1.2);
    opacity: 0.8;
  }
}
#blog-page .list-blog__item-image img {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#blog-page .list-blog__item-image .label {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #575fcc;
  color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
#blog-page .list-blog__item-image .label span {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #fffcfa;
}
#blog-page .list-blog__item-image .label b {
  font-size: 10px;
  line-height: 12px;
  color: #fffcfa;
}
#blog-page .list-blog__item-content {
  padding: 20px;
}
#blog-page .list-blog__item-content .list-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 14px;
}
#blog-page .list-blog__item-content .list-tag a {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #484848;
  background-color: #ebebeb;
  padding: 5px 6px;
  border-radius: 4px;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.1s ease;
}
#blog-page .list-blog__item-content .list-tag a:hover {
  color: #575fcc;
  opacity: 0.8;
}
#blog-page .list-blog__item-content .title {
  color: #333333;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: color 0.1s ease;
  margin-block: 15px;
}
#blog-page .list-blog__item-content .title:hover {
  color: #575fcc;
}
#blog-page .list-blog__item-content .description {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
#blog-page .search-field {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #dadada;
  border-radius: 100rem;
  padding: 6px 6px 6px 14px;
}
#blog-page .search-field input {
  font-family: inherit;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}
#blog-page .search-field input::placeholder {
  color: #aaaaaa;
}
#blog-page .search-field button {
  border-radius: 100rem;
  width: 35px;
  height: 35px;
  background-color: #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: opacity 0.1s ease;
  color: #333333;
}
#blog-page .search-field button:hover {
  opacity: 0.8;
}
#blog-page .search-field button i {
  font-size: 14px;
}
#blog-page .post-caption__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #333333;
  margin-bottom: 32px;
}
#blog-page .post-caption__desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 24px;
}
#blog-page .post-caption__image {
  height: 0;
  position: relative;
  padding-bottom: 55.55%;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
}
#blog-page .post-caption__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#blog-page .post-caption .date {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #484848;
}
#blog-page .post-caption .list-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
#blog-page .post-caption .list-tag__item {
  background-color: #ebebeb;
  border-radius: 4px;
  display: inline-block;
  padding: 5px 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #484848;
  transition: color 0.1s ease;
  text-transform: uppercase;
}
#blog-page .post-caption .list-tag__item:hover {
  color: #575fcc;
}
#blog-page .post-container {
  margin-bottom: 24px;
  max-width: 1060px;
  margin-inline: auto;
}
#blog-page .post-content h1,
#blog-page .post-content h2,
#blog-page .post-content h3,
#blog-page .post-content h4,
#blog-page .post-content h5,
#blog-page .post-content h6 {
  font-weight: 500;
  font-size: clamp(1.125rem, 0.97rem + 0.583vw, 1.25rem);
  line-height: 30px;
  color: #333333;
  margin-bottom: 10px;
}
#blog-page .post-content p {
  color: #333333;
  font-weight: 400;
  font-size: clamp(0.906rem, 0.79rem + 0.437vw, 1rem);
  line-height: 24px;
  margin-bottom: 10px;
}
#blog-page .post-content img {
  max-width: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
  margin-bottom: 30px;
}
#blog-page .post-summary {
  margin-top: 50px;
}
#blog-page .post-summary__contact {
  background-color: #ebebeb;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
#blog-page .post-summary__contact .image {
  max-width: 152px;
  height: 152px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 12px;
  overflow: hidden;
}
#blog-page .post-summary__contact .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#blog-page .post-summary__contact .content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#blog-page .post-summary__contact .content span,
#blog-page .post-summary__contact .content a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #484848;
}
#blog-page .post-summary__contact .content b {
  color: #484848;
  font-size: 16px;
  line-height: 24px;
}
#blog-page .post-summary__contact .content a {
  text-decoration: underline;
  transition: color 0.1s ease;
}
#blog-page .post-summary__contact .content a:hover {
  color: #575fcc;
}
#blog-page .no-result-message {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #333333;
}
#blog-page .no-result-message span {
  color: #575fcc;
}
@media only screen and (max-width: 1199px) {
  #blog-page .post-caption__title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  #blog-page .post-caption__title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 12px;
  }
  #blog-page .post-caption__desc {
    font-size: 15px;
    margin-bottom: 15px;
  }
  #blog-page .post-related .row {
    display: grid;
    justify-content: flex-start;
    grid-auto-columns: 90%;
    grid-auto-flow: column;
    grid-gap: 1.2rem;
    overflow: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-overflow-scrolling: touch;
    scroll-padding: 1rem;
    grid-template-columns: unset;
  }
  #blog-page .post-related .row::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  #blog-page .post-related .row > * {
    scroll-snap-align: start;
  }
  #blog-page .post-summary {
    margin-top: 25px;
  }
  #blog-page .post-summary .post-summary__content {
    margin-block: 10px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 599px) {
  #blog-page .no-result-message {
    font-size: 20px;
    line-height: 30px;
  }
  #blog-page .post-summary__contact {
    flex-direction: column;
  }
  #blog-page .post-summary__contact .content span,
  #blog-page .post-summary__contact .content b {
    font-size: 15px;
  }
  #blog-page .header-blog__caption a {
    font-size: 25px;
  }
  #blog-page .header-blog__menu {
    padding-block: 17px;
  }
  #blog-page .header-blog__menu ul {
    gap: 10px;
  }
  #blog-page .list-blog__item-content {
    padding: 15px;
  }
  #blog-page .list-blog__item-content .title {
    margin-block: 8px;
    font-size: 16px;
  }
}

#faqs-page .accordion-section .accordion-item.active .accordion-item__content {
  display: block;
}
#faqs-page .accordion-section .accordion-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 10px 15px;
  background: #ebebeb;
  border-radius: 4px;
  cursor: pointer;
}
#faqs-page .accordion-section .accordion-item__header i {
  transition: transform 0.3s ease;
  margin-left: auto;
  font-size: 14px;
}
#faqs-page .accordion-section .accordion-item__header i.rotate {
  transform: rotate(180deg);
}
#faqs-page .accordion-section .accordion-item__content {
  display: none;
  margin-block: 6px;
}
#faqs-page .accordion-section .accordion-item__content a {
  padding: 8px 24px;
  color: #484848;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  width: 100%;
  text-align: left;
  transition: color 0.1s ease;
}
#faqs-page .accordion-section .accordion-item__content a:hover {
  color: #575fcc;
}
#faqs-page .purchase .caption {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
#faqs-page .purchase .caption h1 {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #333333;
}
#faqs-page .purchase .caption h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}
#faqs-page .purchase .caption p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #484848;
}
#faqs-page .purchase .step {
  margin-bottom: 24px;
}
#faqs-page .purchase .step-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #484848;
  margin-bottom: 12px;
}
#faqs-page .purchase .step-description {
  color: #484848;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
#faqs-page .purchase .step-image {
  margin-top: 24px;
  width: 85%;
  height: 0;
  position: relative;
  padding-bottom: 37.95%;
  margin-inline: auto;
}
#faqs-page .purchase .step-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#faqs-page .purchase .note {
  border-top: 1px solid #dddde3;
  padding-top: 24px;
  margin-top: 24px;
}
#faqs-page .purchase .note h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #484848;
  margin-bottom: 12px;
}
#faqs-page .purchase .note p {
  color: #484848;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
@media only screen and (max-width: 767px) {
  #faqs-page .purchase .step-image {
    width: 100%;
    padding-bottom: 50%;
  }
  #faqs-page .purchase .caption {
    gap: 10px;
  }
  #faqs-page .purchase .caption h1 {
    font-size: 24px;
  }
}

#track-order-page .banner {
  background-image: url("../images/order-banner.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  position: relative;
  height: clamp(25rem, -50rem + 100vw, 37.5rem);
  width: 100%;
}
#track-order-page .banner-content {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#track-order-page .banner-content__title {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #484848;
}
#track-order-page .banner-content__search {
  background-color: #fffcfa;
  border-radius: 100rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 6px 6px 6px 16px;
  position: relative;
  border: 1px solid #dadada;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.25s ease;
  height: 48px;
}
#track-order-page .banner-content__search:focus-within {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
}
#track-order-page .banner-content__search input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
#track-order-page .banner-content__search input::placeholder {
  color: #aaa;
}
#track-order-page .banner-content__search button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 100rem;
  width: 34px;
  height: 34px;
  background-color: #575fcc;
  transition: opacity 0.25s ease;
}
#track-order-page .banner-content__search button:hover {
  opacity: 0.8;
}
#track-order-page .banner-content__search button img {
  width: 15px;
}
#track-order-page .no-result-message {
  margin-top: 48px;
  margin-bottom: 72px;
}
#track-order-page .no-result-message .caption {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #333333;
  margin-bottom: 24px;
}
#track-order-page .no-result-message .desc span,
#track-order-page .no-result-message .desc a {
  font-size: 16px;
  line-height: 24px;
}
#track-order-page .no-result-message .desc span {
  color: #333333;
  font-weight: 400;
}
#track-order-page .no-result-message .desc a {
  font-weight: 600;
  color: #575fcc;
  transition: opacity 0.25s ease;
}
#track-order-page .no-result-message .desc a:hover {
  opacity: 0.8;
}
#track-order-page .order-status + .order-status {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #dadada;
}
#track-order-page .order-status__header {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
#track-order-page .order-status__header b {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}
#track-order-page .order-status__header h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #575fcc;
}
#track-order-page .order-status .order-item {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
#track-order-page .order-status .order-item + .order-item {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid #dadada;
}
#track-order-page .order-status .order-item__info {
  display: flex;
  gap: 24px;
}
#track-order-page .order-status .order-item__image {
  max-width: 58px;
  height: 58px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
#track-order-page .order-status .order-item__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#track-order-page .order-status .order-item__details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#track-order-page .order-status .order-item__details a,
#track-order-page .order-status .order-item__details p {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
#track-order-page .order-status .order-item__details a {
  transition: color 0.1s ease;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
#track-order-page .order-status .order-item__details a:hover {
  color: #575fcc;
}
#track-order-page .order-status .order-item__price {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #333333;
  text-align: right;
}
#track-order-page .order-status .order-total {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #484848;
}
#track-order-page .register-account {
  margin-top: 24px;
  text-align: left;
}
#track-order-page .register-account a {
  color: #575fcc;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  transition: opacity 0.25s ease;
}
#track-order-page .register-account a:hover {
  opacity: 0.8;
}
#track-order-page .register-account span {
  color: #333333;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
@media only screen and (max-width: 1199px) {
  #track-order-page .banner {
    height: auto;
    aspect-ratio: 16/9;
  }
}
@media only screen and (max-width: 599px) {
  #track-order-page .banner .banner-content {
    gap: 20px;
  }
  #track-order-page .banner .banner-content__title {
    font-size: 18px;
    line-height: 26px;
  }
  #track-order-page .banner .banner-content__search input {
    font-size: 12px;
  }
  #track-order-page .no-result-message .caption {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  #track-order-page .no-result-message .desc span,
  #track-order-page .no-result-message .desc a {
    font-size: 15px;
  }
  #track-order-page .order-status .order-item {
    gap: 15px;
  }
  #track-order-page .order-status .order-item__info {
    gap: 14px;
  }
}
@media only screen and (max-width: 424px) {
  #track-order-page .order-status__header {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }
  #track-order-page .register-account a,
  #track-order-page .register-account span {
    font-size: 14px;
  }
}