#home-page {
  .banner {
    &-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 20px;
      aspect-ratio: 7.3 / 2.3;
      &__image {
        border-radius: 20px;
        overflow: hidden;
        &:nth-child(1) {
          grid-column: 1 / 5;
          grid-row: 1 / 3;
        }
        &:nth-child(2) {
          grid-column: 5 / 7;
          grid-row: 1 / 2;
        }
        &:nth-child(3) {
          grid-column: 5 / 7;
          grid-row: 2 / 3;
        }
        img {
          @include size(100%);
          object-fit: cover;
        }
      }
    }
  }
  .services {
    margin-top: 40px;
    &__item {
      display: flex;
      align-items: center;
      gap: 24px;
      padding: clamp(1.563rem, -0.991rem + 3.989vw, 2.5rem);
      border: 1.5px solid #dae5fb;
      border-radius: 24px;
      span {
        color: #ff6d3b;
      }
    }
  }
  .category {
    margin: 100px 0;
    &-item {
      display: inline-block;
      transition: filter 0.2s ease;
      border-radius: 20px;
      width: 100%;
      overflow: hidden;
      height: 0;
      position: relative;
      padding-bottom: 91.85%;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        background-color: rgba(0, 0, 0, 0.2);
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        object-fit: cover;
      }
      &:hover {
        filter: brightness(1.2);
      }
      &__content {
        @include blockCenter("left");
        z-index: 10;
        top: 0;
        width: 100%;
        text-align: center;
        padding: 10px 5px;
        h3 {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #fffcfa;
        }
      }
    }
  }
  .product-list {
    --column: 5;
    display: grid;
    gap: 40px 16px;
    grid-template-columns: repeat(var(--column), minmax(0, 1fr));
  }
  @include maxWidth(1199px) {
    .category {
      margin: 60px 0;
    }
    .product-list {
      --column: 3;
    }
  }
  @include maxWidth(1023px) {
    .banner-list {
      display: grid;
      justify-content: flex-start;
      grid-auto-columns: 90%;
      grid-auto-flow: column;
      grid-gap: 1.2rem;
      overflow: auto;
      overflow-y: hidden;
      scroll-snap-type: x mandatory;
      scroll-snap-stop: always;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      -webkit-overflow-scrolling: touch;
      scroll-padding: 1rem;
      grid-template-columns: unset;
      aspect-ratio: 6.8 / 3;
      &::-webkit-scrollbar {
        display: none;
        width: 0;
      }
      & > * {
        scroll-snap-align: start;
      }
      &__image:not(:nth-child(999)) {
        grid-column: unset;
        grid-row: unset;
      }
    }
  }
  @include maxWidth(767px) {
    .product-list {
      --column: 2;
    }
  }
  @include maxWidth(374px) {
    .product-list {
      --column: 1;
    }
  }
}