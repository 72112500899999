#track-order-page {
  .banner {
    @include background-image(
      "../images/order-banner.jpg",
      "../../../../assets/images/order-banner.jpg"
    );
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    position: relative;
    height: clamp(25rem, -50rem + 100vw, 37.5rem);
    width: 100%;
    &-content {
      max-width: 650px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 80px;
      padding: 20px;
      @include blockCenter("both");
      &__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        color: #484848;
      }
      &__search {
        background-color: $light-color;
        border-radius: 100rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 6px 6px 6px 16px;
        position: relative;
        border: 1px solid #dadada;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: all 0.25s ease;
        height: 48px;
        &:focus-within {
          border-color: #86b7fe;
          box-shadow: 0 0 0 0.25rem rgba(14, 110, 253, 0.25);
        }
        input {
          border: none;
          outline: none;
          @include size(100%);
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $primary-text;
          &::placeholder {
            color: #aaa;
          }
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          border-radius: 100rem;
          @include size(34px);
          background-color: $primary-color;
          transition: opacity 0.25s ease;
          &:hover {
            opacity: 0.8;
          }
          img {
            width: 15px;
          }
        }
      }
    }
  }
  .no-result-message {
    margin-top: 48px;
    margin-bottom: 72px;
    .caption {
      font-weight: 500;
      font-size: 30px;
      line-height: 40px;
      color: $primary-text;
      margin-bottom: 24px;
    }
    .desc {
      span,
      a {
        font-size: 16px;
        line-height: 24px;
      }
      span {
        color: $primary-text;
        font-weight: 400;
      }
      a {
        font-weight: 600;
        color: $primary-color;
        transition: opacity 0.25s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .order-status {
    & + .order-status {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid #dadada;
    }
    &__header {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      b {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #484848;
      }
      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: $primary-color;
      }
    }
    .order-item {
      display: grid;
      grid-template-columns: 1fr 0.1fr;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      & + .order-item {
        margin-top: 18px;
        padding-top: 18px;
        border-top: 1px solid #dadada;
      }
      &__info {
        display: flex;
        gap: 24px;
      }
      &__image {
        max-width: 58px;
        height: 58px;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        img {
          object-fit: cover;
          @include size(100%);
        }
      }
      &__details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        a,
        p {
          color: $primary-text;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
        a {
          transition: color 0.1s ease;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          &:hover {
            color: $primary-color;
          }
        }
      }
      &__price {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: $primary-text;
        text-align: right;
      }
    }
    .order-total {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #484848;
    }
  }
  .register-account {
    margin-top: 24px;
    text-align: left;
    a {
      color: $primary-color;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      transition: opacity 0.25s ease;
      &:hover {
        opacity: 0.8;
      }
    }
    span {
      color: $primary-text;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }
  }
  // Responsive all screen
  @include maxWidth(1199px) {
    .banner {
      height: auto;
      aspect-ratio: 16 / 9;
    }
  }
  @include maxWidth(599px) {
    .banner {
      .banner {
        &-content {
          gap: 20px;
          &__title {
            font-size: 18px;
            line-height: 26px;
          }
          &__search input {
            font-size: 12px;
          }
        }
      }
    }
    .no-result-message {
      .caption {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
      }
      .desc {
        span,
        a {
          font-size: 15px;
        }
      }
    }
    .order-status {
      .order-item {
        gap: 15px;
        &__info {
          gap: 14px;
        }
      }
    }
  }
  @include maxWidth(424px) {
    .order-status__header {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
    .register-account {
      a,
      span {
        font-size: 14px;
      }
    }
  }
}